import tw from "twin.macro";
import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import magazine from "../../images/magazine.png"
import plus from "../../images/plus.png";
import {Redirect} from "react-router-dom";
import UserService from "../../services/user.service";
import {toastError} from "../common/Toast";
import {Title, TitleBis} from "../common/Elements";

const Container = tw.div`bg-white rounded-lg p-5 py-8 relative`;
const MagazineIcon = tw.img`inline-block mt--2`;
const CurrentBalance = tw.div`text-purple-pastel text-2xl ml-5 flex flex-col items-center`;

const RefillWrapper = tw.div`mt-8 ml-5`;
const RefillAdd = tw.img`rounded-full w-20 mx-auto mt-5`;
const RefillSub = tw.div`text-center mt-8 text-purple-pastel`;

const LastBuyWrapper = tw.div`mt-8 text-center`;
const BuyLine = tw.div`mt-2 text-purple-pastel`;

class Subscription extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: "",
            transactions: []
        };

        this.dateOption = {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
        };
    }

    componentDidMount() {
        this.loadLastTransactions();
    }

    loadLastTransactions = () => {
        UserService.getLastTransactions(this.props.subscriber.subscriberId)
            .then(resp => {
                this.setState({
                    transactions: resp.data
                })
            })
            .catch(err => {
                toastError(this.props.t("ERROR_SERVER"));
            });
    }

    openPayment = () => {
        this.setState({
            redirect: "/payment/" + this.props.subscriber.inviteToken
        })
    }

    render() {
        if (this.state.redirect !== "") {
            return <Redirect to={this.state.redirect}/>
        }

        const {t} = this.props;
        const sub = this.props.subscriber;

        let text = '';
        if (sub.paidMagazine < 2) {
            text = t('MOB_SUBSCRIPTION_SUFIX_ONE').replace('%NUMBER%', sub.paidMagazine);
        } else {
            text = t('MOB_SUBSCRIPTION_SUFIX_MANY').replace('%NUMBER%', sub.paidMagazine);
        }

        return (
            <Container>
                <CurrentBalance>
                    <h2 className={"text-center"}>
                        <MagazineIcon src={magazine} alt={"Magazine"}/>
                        {t("MOB_SUBSCRIPTION_PREFIX")}
                        {text}
                    </h2>
                </CurrentBalance>
                <RefillWrapper>
                    <TitleBis onClick={this.openPayment}>
                        {t("SUBSCRIPTION_REFILL_HEADER")}
                    </TitleBis>
                    <RefillAdd onClick={this.openPayment} src={plus}/>
                    <RefillSub onClick={this.openPayment}>
                        {t("SUBSCRIPTION_REFILL_DETAIL")}
                    </RefillSub>
                </RefillWrapper>
                <LastBuyWrapper>
                    <TitleBis>
                        {t("SUBSCRIPTION_LAST_BUY_HEADER")}
                    </TitleBis>
                    {
                        this.state.transactions.map(transac => {
                            let text = "";
                            if (transac.nbMagazines < 2) {
                                text = t("SUBSCRIPTION_BUY_LINE");
                            } else {
                                text = t("SUBSCRIPTION_BUY_LINE_MANY");
                            }

                            text = text.replace("%NAME%", transac.firstName);
                            text = text.replace("%NB%", transac.nbMagazines);
                            const date = new Date(transac.transactionDate).toLocaleDateString(this.props.language, this.dateOption);
                            text = text.replace("%DATE%", date);

                            return (
                                <BuyLine key={transac.transactionDate}>
                                    {text}
                                </BuyLine>
                            )
                        })
                    }
                </LastBuyWrapper>
            </Container>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        language: state.profile.language,
        subscriber: state.currentSub !== undefined ? state.profile.subscribers[state.currentSub] : undefined,
    }
};

export default connect(mapStateToProps, null)(withTranslation()(Subscription));
