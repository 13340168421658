import React from 'react';

export default class Tracking extends React.Component {

    componentDidMount() {
        const gaScript = document.createElement('script');
        gaScript.type = 'application/javascript';
        gaScript.async = true;
        gaScript.src = "https://www.googletagmanager.com/gtag/js?id=G-9VQMCMBVP1";

        const ga = document.createElement('script');
        ga.type = 'application/javascript';
        ga.async = true;
        ga.innerHTML = "window.dataLayer = window.dataLayer || [];\n" +
            "          function gtag(){dataLayer.push(arguments);}\n" +
            "          gtag('js', new Date());\n" +
            "          gtag('config', 'G-9VQMCMBVP1');\n";

        const fbPixel = document.createElement('script');
        fbPixel.type = 'application/javascript';
        fbPixel.async = true;
        fbPixel.innerHTML = "!function(f,b,e,v,n,t,s)\n" +
            "            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n" +
            "            n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n" +
            "            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n" +
            "            n.queue=[];t=b.createElement(e);t.async=!0;\n" +
            "            t.src=v;s=b.getElementsByTagName(e)[0];\n" +
            "            s.parentNode.insertBefore(t,s)}(window, document,'script',\n" +
            "            'https://connect.facebook.net/en_US/fbevents.js');\n" +
            "            fbq('init', '3587916501299478');\n" +
            "            fbq('track', 'PageView');\n";

        const snapPixel = document.createElement('script');
        snapPixel.type = 'application/javascript';
        snapPixel.async = true;
        snapPixel.innerHTML = "(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()\n" +
            "          {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};\n" +
            "            a.queue=[];var s='script';r=t.createElement(s);r.async=!0;\n" +
            "            r.src=n;var u=t.getElementsByTagName(s)[0];\n" +
            "            u.parentNode.insertBefore(r,u);})(window,document,\n" +
            "                  'https://sc-static.net/scevent.min.js');\n" +
            "          snaptr('init', '23a6be10-6856-442b-8485-6ab3caa8ecc3');\n" +
            "          snaptr('track', 'PAGE_VIEW');\n";

        const linkedinScript = document.createElement('script');
        linkedinScript.type = 'application/javascript';
        linkedinScript.async = true;
        linkedinScript.innerHTML = "(function(){var s = document.getElementsByTagName(\"script\")[0];\n" +
            "      var b = document.createElement(\"script\");\n" +
            "      b.type = \"text/javascript\";b.async = true;\n" +
            "      b.src = \"https://snap.licdn.com/li.lms-analytics/insight.min.js\";\n" +
            "      s.parentNode.insertBefore(b, s);})();\n";

        const linkedin = document.createElement('script');
        linkedin.type = 'application/javascript';
        linkedin.async = true;
        linkedin.innerHTML = "_linkedin_partner_id = \"2980033\";\n" +
            "    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];\n" +
            "    window._linkedin_data_partner_ids.push(_linkedin_partner_id);\n";


        this.instance.appendChild(gaScript);
        this.instance.appendChild(ga);
        this.instance.appendChild(fbPixel);
        this.instance.appendChild(snapPixel);
        this.instance.appendChild(linkedinScript);
        this.instance.appendChild(linkedin);
    }

    render() {
        return <div ref={el => (this.instance = el)}/>;
    }
}
