import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Comment from "./Comment";
import tw from "twin.macro";
import styled from "styled-components";

const ComNav = styled.a`
${props => props.show ? tw`visible` : tw`invisible`}
${tw`text-xs float-right mr-2 mt-1 mb-2 text-purple-pastel border-b-1 border-transparent hover:border-purple-pastel`}
`;

const COMMENT_TO_DISPLAY = 5;

class CommentList extends React.Component {

    constructor(props) {
        super(props);

        let begin = 0;

        if (this.props.showEnd) {
            begin = this.props.nbComments - COMMENT_TO_DISPLAY;
            if (begin < 0) begin = 0;
        }

        this.state = {
            publication: this.props.publication,
            comments: this.props.comments,
            nbComments: this.props.nbComments,
            beginDisplay: begin
        }
    }

    next = () => {
        const current = this.state.beginDisplay;
        this.setState({
            beginDisplay: (current + 1)
        })
    }

    prev = () => {
        const current = this.state.beginDisplay;
        this.setState({
            beginDisplay: (current - 1)
        })
    }

    loadAllComments = () => {
        this.props.loadAll();
    }

    render() {
        const {t} = this.props;

        if (this.state.comments === null) {
            return null;
        }

        if (this.state.comments.length === 1) {
            const com = this.state.comments[0];

            return (
                <div>
                    <Comment
                        key={com.commentId}
                        comment={com}
                        onDelete={this.props.refreshCallback}
                        magazineOpen={this.props.magazineOpen}
                    />
                    {
                        this.state.nbComments > 2 ? (
                            <ComNav
                                onClick={this.loadAllComments}
                                show={true}
                            >
                                {t("COMMENT_SEE_ALL_MANY").replace("%NUMBER%", this.state.nbComments - 1)}
                            </ComNav>
                        ) : this.state.nbComments === 2 ? (
                            <ComNav
                                onClick={this.loadAllComments}
                                show={true}
                            >
                                {t("COMMENT_SEE_ALL_ONE")}
                            </ComNav>
                        ) : null
                    }
                </div>
            )
        }

        const toDisplay = this.state.comments.slice(this.state.beginDisplay, this.state.beginDisplay + COMMENT_TO_DISPLAY);

        const endIdx = this.state.beginDisplay + COMMENT_TO_DISPLAY;
        const showNext = endIdx < this.state.nbComments;
        const showPrev = this.state.beginDisplay > 0;

        return (
            <div>
                <ComNav onClick={this.prev} show={showPrev}>{t("COMMENT_PREV")}</ComNav>
                {
                    toDisplay.map(com => {
                        return (
                            <Comment
                                key={com.commentId}
                                comment={com}
                                onDelete={this.props.refreshCallback}
                                magazineOpen={this.props.magazineOpen}
                            />
                        )
                    })
                }
                <ComNav onClick={this.next} show={showNext}>{t("COMMENT_NEXT")}</ComNav>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.profile.language,
        profile: state.profile
    }
};

export default connect(mapStateToProps, null)(withTranslation()(CommentList));
