import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import tw from "twin.macro";
import {ReactComponent as CloseIcon} from "feather-icons/dist/icons/x.svg";
import plus from "../../images/plus.png";
import {setCurrentSub} from "../../redux/actions";
import {Redirect} from "react-router-dom";

const Popup = tw.div`
fixed top-0 left-0 text-center overflow-y-scroll
z-40 w-full bg-white h-screen p-8 shadow-popup
sm:top-1/2 sm:left-1/2 sm:h-auto sm:w-3/5
sm:transform sm:translate-y--1/2 sm:translate-x--1/2
sm:rounded-lg sm:max-h-80
`;

const CloseWrapper = tw.div`text-black absolute top-0 right-0 mt-3 mr-3`;
const Title = tw.h2`text-purple-classic text-2xl  mb-5`
const Subscriber = tw.div`flex justify-center max-w-xl mx-auto`;
const Picture = tw.img`block rounded-full w-24 h-24 my-3 sm:w-32 sm:h-32 sm:my-5 z-30`;
const NameWrapper = tw.a`my-auto bg-green-pastel hover:bg-green-classic
 w-full flex justify-center flex-col
 h-24 rounded-full
 sm:h-32 ml-10 items-center
 `;
const Name = tw.span`align-middle text-purple-classic sm:text-xl text-left`;

class SubSelector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: ""
        }
    }

    openSubscriber = (subId) => {
        this.props.updateSub(subId);
        this.closePopup();
    }

    redirectToSignup = () => {
        this.setState({
            redirect: "/signup/new"
        })
    }

    closePopup = () => {
        this.props.closeFunction();
    }

    render() {

        const {t} = this.props;

        if (this.state.redirect !== "") {
            return <Redirect to={this.state.redirect}/>
        }

        return (
            <Popup>
                <CloseWrapper onClick={this.closePopup}>
                    <CloseIcon tw="w-6 h-6"/>
                </CloseWrapper>
                <Title>{t("HOME_POPUP_TITLE")}</Title>
                {
                    this.props.subscribers.map(sub => {
                        return (
                            <Subscriber key={sub.subscriberId}>
                                <Picture
                                    alt={"profile"}
                                    src={sub.image}
                                    onClick={() => this.openSubscriber(sub.subscriberId)}
                                />
                                <NameWrapper
                                    onClick={() => this.openSubscriber(sub.subscriberId)}
                                >
                                    <Name>{sub.firstName}</Name>
                                </NameWrapper>
                            </Subscriber>
                        )
                    })
                }

                <Subscriber>
                    <Picture
                        src={plus}
                        onClick={this.redirectToSignup}
                    />
                    <NameWrapper
                        onClick={this.redirectToSignup}
                    >
                        <Name>{t("HOME_POPUP_ADD")}</Name>
                    </NameWrapper>
                </Subscriber>

            </Popup>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        subscribers: state.profile.subscribers
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateSub: (id) => dispatch(setCurrentSub(id))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SubSelector));
