export default {
    baseApiUrl: process.env.REACT_APP_API_URL,
    sentryDNS: process.env.REACT_APP_SENTRY_DNS,
    env: process.env.REACT_APP_ENV,
    cgvUrl: "https://mag-iris.com/cgv",
    cguUrl: "https://mag-iris.com/cgu",
    confidentialiteUrl: "https://mag-iris.com/confidentialite",
    loginEndpoint: "/client/login",
    resetPasswordEndpoint: "/client/resetpassword",
    newPasswordEndpoint: "/client/newpassword/",
    profileEndpoint: "/client/profile",
    updateCusImageEndpoint: "/client/profile/picture",
    updateSubImageEndpoint: "/client/sub/picture/",
    familyEndpoint: "/client/family/",
    deleteFamilyEndpoint: "/client/family/",
    addFamilyAdminEndpoint: "/client/family/admin/",
    deleteFamilyAdminEndpoint: "/client/family/admin/",
    sendFamilyMessageEndpoint: "/client/family/message/",
    inviteFamilyEndpoint: "/client/family/invite/",
    lastTransactionsEndpoint: "/client/subscriber/transactions/",
    magazinesEndpoint: "/magazine/",
    oldMagazinesEndpoint: "/magazine/old/",
    publicationEndpoint: "/magazine/publication/",
    publicationsEndpoint: "/magazine/publications/",
    publicationUploadImageEndpoint: "/magazine/image/",
    commentPublicationEndpoint: "/magazine/comment/",
    createCustomer: "/client/customer",
    updateCustomer: "/client/customer",
    createSubscriber: "/client/subscriber",
    updateSubscriber: "/client/subscriber/",
    updateSubscriberPref: "/client/sub/magazine/pref/",
    associateSubscriber: "/client/associate",
    getSubscriberName: "/client/subscriber/",
    getFormulas: "/subscription/formula/",
    getPromoCode: "/subscription/promo/",
    createTransaction: "/subscription/transaction/",
    stripePublicKey: process.env.REACT_APP_STRIPE_KEY,
    countriesAvailable: ["al", "ad", "at", "by", "be", "ba", "bg", "hr", "cy", "cz", "dk", "ee", "fi", "fr", "de", "gr", "hu", "is", "ie", "it", "xk", "lv", "li", "lt", "mk", "mt", "md", "mc", "me", "nl", "no", "pl", "pt", "ro", "ru", "sm", "rs", "sk", "si", "es", "se", "ch", "tr", "ua", "gb", "va"],
    countriesAvailableUpper: ["AL", "AD", "AT", "BY", "BE", "BA", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IS", "IE", "IT", "XK", "LV", "LI", "LT", "MK", "MT", "MD", "MC", "ME", "NL", "NO", "PL", "PT", "RO", "RU", "SM", "RS", "SK", "SI", "ES", "SE", "CH", "TR", "UA", "GB", "VA"],
    defaultCountries: ["ch", "fr", "it", "de"],
    defaultCountriesUpper: ["CH", "FR", "IT", "DE"],
    languages: {FR: "Français", IT: "Italiano", DE: "Deutsch"},
    cantons: {
        AG: "Aargau",
        AR: "Appenzell Ausserrhoden",
        AI: "Appenzell Innerrhoden",
        BL: "Basel-Landschaft",
        BS: "Basel-Stadt",
        BE: "Bern",
        FR: "Freiburg",
        GE: "Genève",
        GL: "Glarus",
        GR: "Graubünden",
        JU: "Jura",
        LU: "Luzern",
        NE: "Neuchâtel",
        NW: "Nidwalden",
        OW: "Obwalden",
        SG: "Sankt Gallen",
        SH: "Schaffhausen",
        SZ: "Schwyz",
        SO: "Solothurn",
        TG: "Thurgau",
        TI: "Ticino",
        UR: "Uri",
        VS: "Valais",
        VD: "Vaud",
        ZG: "Zug",
        ZH: "Zürich"
    },
    customerSource: [
        "WEB",
        "SOCIAL",
        "ADS",
        "PRESS",
        "ASSOC",
        "WORD_OF_MOUTH",
        "OTHER"
    ],
    relationShips: [
        "SON",
        "GRANDSON",
        "NEPHEW",
        "OTHER"
    ]
}
