import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {
    ButtonWithoutMargin,
    SignupFormulaCurrency,
    SignupFormulaIcon, SignupFormulaPrice, SignupFormulaSub,
    SignupFormulaWrapper, SignupStepButtonLine, SignupStepButtonWrapper,
    SignupStepContent, SignupStepHeader,
    SignupStepNumber, SignupStepSubTitle,
    SignupStepTitle,
    SignupStepTitleWrapper,
    SignupStepWrapper
} from "../common/Elements";

import number from "../../images/signup/magiris-inscription_03.png";
import formula1 from "../../images/signup/magiris-formule_x1.png";
import formula2 from "../../images/signup/magiris-formule_x6.png";
import formula3 from "../../images/signup/magiris-formule_x12.png";
import tw from "twin.macro";
import {toastError} from "../common/Toast";
import SubscriptionService from "../../services/subscription.service";
import {priceFormulaFormat} from "../../services/format";
import Loader from "../common/Loader";

const FormulaInvitation = tw.div`mt-3 mb-8 text-lg`;

class SignupStep3 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formula: undefined,
            formulaIdx: undefined,
            currency: "CHF",
            loading: true,
            formulas: []
        };

        this.formulaStyle = [
            {
                image: formula1,
                className: "border-green-pastel hover:bg-green-pastel",
                classSubName: "bg-green-pastel"
            },
            {
                image: formula2,
                className: "border-green-classic hover:bg-green-pastel",
                classSubName: "bg-green-pastel"
            },
            {
                image: formula3,
                className: "border-purple-pastel bg-purple-pastel hover:bg-purple-classic text-white",
                classSubName: "bg-white text-purple-pastel"
            }
        ]
    }

    componentDidMount() {
        //TODO select by country
        SubscriptionService.getFormulas("CH", this.state.currency)
            .then(resp => {
                this.setState({
                    loading: false,
                    formulas: resp.data
                })
            })
            .catch(err => {
                toastError(this.props.t("ERROR_SERVER"));
            });
    }

    activeStep = () => {
        if (!this.props.isInvitation && this.state.formula !== undefined) {
            this.setState({
                formula: undefined,
                formulaIdx: undefined,
            }, this.props.handleSubmit(3, undefined));
        }
    }

    selectFormula = (formula, idx) => {
        if (this.props.isActive) {
            this.setState({
                formula: formula,
                formulaIdx: idx
            }, this.props.handleSubmit(3, formula));
        }
    }

    deselectFormula = () => {
        this.setState({
            formula: undefined,
            formulaIdx: undefined,
        }, this.props.handleSubmit(3, undefined));
    }

    render() {
        const {t} = this.props;

        return (
            <SignupStepWrapper className={!this.props.isActive && "opacity-50"}>
                <SignupStepHeader className={"cursor-pointer"} onClick={this.activeStep}>
                    <SignupStepNumber src={number}/>
                    <SignupStepTitleWrapper>
                        <SignupStepSubTitle>{t("SIGNUP_STEP3_TITLE")}</SignupStepSubTitle>
                        <SignupStepTitle>{t("SIGNUP_STEP3_SUBTITLE")}</SignupStepTitle>
                    </SignupStepTitleWrapper>
                </SignupStepHeader>
                <SignupStepContent>
                    {
                        this.props.isInvitation ? (
                            <FormulaInvitation>
                                {t("SIGNUP_STEP3_ALREADY_ACTIVE").replace('%NAME%', this.props.subName)}
                            </FormulaInvitation>
                        ) : (
                            <div>
                                {
                                    this.state.formula !== undefined ? (
                                        <SignupFormulaWrapper
                                            className={this.formulaStyle[this.state.formulaIdx].className}
                                            onClick={() => this.deselectFormula()}
                                        >
                                            <SignupFormulaIcon src={this.formulaStyle[this.state.formulaIdx].image}/>
                                            <SignupFormulaPrice>{priceFormulaFormat(this.state.currency, this.state.formula.amountWithVAT)}</SignupFormulaPrice>
                                            <SignupFormulaCurrency>{this.state.currency}</SignupFormulaCurrency>
                                            <SignupFormulaSub
                                                className={this.formulaStyle[this.state.formulaIdx].classSubName}>{t(this.state.formula.details)}</SignupFormulaSub>
                                        </SignupFormulaWrapper>
                                    ) : (
                                        <div>
                                            {
                                                this.state.loading ? (
                                                    <Loader/>
                                                ) : null
                                            }
                                            {
                                                this.state.formulas.map((formula, idx) => {
                                                    return (
                                                        <SignupFormulaWrapper
                                                            className={this.formulaStyle[idx].className}
                                                            onClick={() => this.selectFormula(formula, idx)}
                                                        >
                                                            <SignupFormulaIcon src={this.formulaStyle[idx].image}/>
                                                            <SignupFormulaPrice>{priceFormulaFormat(this.state.currency, formula.amountWithVAT)}</SignupFormulaPrice>
                                                            <SignupFormulaCurrency>{this.state.currency}</SignupFormulaCurrency>
                                                            <SignupFormulaSub
                                                                className={this.formulaStyle[idx].classSubName}>{t(formula.details)}</SignupFormulaSub>
                                                        </SignupFormulaWrapper>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </SignupStepContent>
            </SignupStepWrapper>
        )

    }

}

export default connect(null, null)(withTranslation()(SignupStep3));
