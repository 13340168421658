import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {disconnect} from "../../redux/actions";
import logo from "../../images/iris_logo_violet.png";
import tw from "twin.macro";
import styled from "styled-components";
import {motion} from "framer-motion";
import {ReactComponent as MenuIcon} from "feather-icons/dist/icons/menu.svg";
import {ReactComponent as CloseIcon} from "feather-icons/dist/icons/x.svg";
import {Link} from "react-router-dom";

const HeaderWrapper = tw.div`bg-white shadow-iris`;
const HeaderContent = tw.div`flex justify-between mx-auto py-2 lg:w-8/12 lg:mx-auto relative h-24`;
const Div = tw.div``;
const LogoContainer = styled(Div)`
 img {
    ${tw`w-24 mt-3 ml-8 lg:ml-0 `}
  }
`;

const NavLinks = tw.div`inline-block`;

const DesktopNavLinks = tw.nav`
  hidden lg:flex justify-between items-center
`;

const MobileNavLinksContainer = tw.nav`flex items-center justify-between lg:hidden mr-8`;

const MobileNav = motion.custom(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg bg-white`}
`);

const MobileNavLinks = tw.div`block flex flex-col items-center`;

const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-black transition duration-300
`;

const NavLink = tw.div`
  text-xl my-2 text-sm lg:mx-6 lg:my-0 lowercase inline-block
  font-normal tracking-wide transition duration-300 text-black
  pb-1 border-b-2 border-transparent hover:border-black flex-1
`;

const NavLinkFull = tw.div`
  px-8 py-3 rounded-full bg-green-pastel text-black lowercase
  hocus:bg-green-classic hocus:text-black focus:shadow-outline
  text-center lg:mx-0 font-medium text-sm inline-block
  border-b-0`;

const ProfileImg = tw.img`rounded-full w-16 h-16 inline-block mb-2 lg:mb-0`;
const ProfileName = tw.h2`text-purple-classic font-brick text-2xl mb-2`;
const Separator = tw.div`mt-8 mb-3 border-t-2 w-20 mx-auto`;

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showNavLinks: false,
        };
    }

    disconnect = () => {
        this.props.disconnect();
    }

    toggleNavbar = () => {
        const active = this.state.showNavLinks;
        this.setState({
            showNavLinks: !active,
            animation: active ? {x: "150%", display: "none"} : {x: "0%", display: "block"}
        });
    }

    render() {
        const {t} = this.props;
        const collapseBreakpointCss = {
            mobileNavLinks: tw`lg:hidden`,
            desktopNavLinks: tw`lg:flex`,
            mobileNavLinksContainer: tw`lg:hidden`
        }

        return (
            <HeaderWrapper>
                <HeaderContent>
                    <LogoContainer>
                        <Link to={"/"}>
                            <img src={logo} alt="logo"/>
                        </Link>
                    </LogoContainer>
                    <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
                        <NavLinks>
                            <ProfileImg src={this.props.profile.picture}/>
                            <NavLink>
                                <Link to={"/profile"}>{t("HEADER_PROFILE")}</Link>
                            </NavLink>
                            <NavLinkFull onClick={this.disconnect}>
                                {t("HEADER_LOGOUT")}
                            </NavLinkFull>
                        </NavLinks>
                    </DesktopNavLinks>
                    <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
                        <MobileNav initial={{x: "150%", display: "none"}} animate={this.state.animation}
                                   css={collapseBreakpointCss.mobileNavLinks}>
                            <MobileNavLinks>
                                <ProfileImg src={this.props.profile.picture}/>
                                <NavLink>
                                    <Link
                                        to={"/profile"}
                                        onClick={this.toggleNavbar}
                                    >
                                        {t("HEADER_PROFILE")}
                                    </Link>
                                </NavLink>
                                <NavLinkFull onClick={this.disconnect}>{t("HEADER_LOGOUT")}</NavLinkFull>
                            </MobileNavLinks>
                            <Separator/>
                            <MobileNavLinks>
                                <ProfileImg src={this.props.subscriber?.image}/>
                                <ProfileName>{this.props.subscriber?.firstName}</ProfileName>
                                {
                                    this.props.menus.map(path => {
                                        return (
                                            <NavLink key={path}>
                                                <Link
                                                    to={"/" + path}
                                                    onClick={this.toggleNavbar}
                                                >
                                                    {t("HOME_MENU_" + path.toUpperCase())}
                                                </Link>
                                            </NavLink>
                                        )
                                    })
                                }
                            </MobileNavLinks>
                        </MobileNav>
                        <NavToggle onClick={this.toggleNavbar} className={this.state.showNavLinks ? "open" : "closed"}>
                            {this.state.showNavLinks ? <CloseIcon tw="w-6 h-6"/> : <MenuIcon tw="w-6 h-6"/>}
                        </NavToggle>
                    </MobileNavLinksContainer>

                </HeaderContent>
            </HeaderWrapper>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        disconnect: () => dispatch(disconnect())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header));
