export const LOGIN = "LOGIN";
export const SET_PROFILE = "SET_PROFILE";
export const SET_JWT = "SET_JWT";
export const SET_CURRENT_SUB = "SET_CURRENT_SUB";
export const SET_SIGNUP_PROCESS = "SET_SIGNUP_PROCESS";
export const DISCONNECT = "DISCONNECT";

export const login = (profile, jwt) => ({
    type: LOGIN,
    profile: profile,
    jwt: jwt,
})

export const setProfile = (profile) => ({
    type: SET_PROFILE,
    profile: profile,
})

export const setJwt = (jwt) => ({
    type: SET_JWT,
    jwt: jwt,
})

export const setCurrentSub = (subId) => ({
    type: SET_CURRENT_SUB,
    subId: subId,
})

export const setSignupProcess = (bool) => ({
    type: SET_SIGNUP_PROCESS,
    bool: bool,
})


export const disconnect = () => ({
    type: DISCONNECT
})

