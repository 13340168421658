import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {ButtonPurple, Input, Logo, Page, Title} from "./common/Elements";
import tw from "twin.macro";
import logo from "../images/iris_logo_violet.png";
import {validateEmail} from "../services/tools";
import Loader from "./common/Loader";
import UserService from "../services/user.service";
import {toastError, toastSuccess} from "./common/Toast";
import {Redirect} from "react-router-dom";

const Container = tw.div`p-5 h-screen sm:pt-10v`;
const Content = tw.div`sm:h-80v sm:w-9/12 sm:mx-auto rounded-lg bg-white pt-3 pb-8 sm:py-10 text-center`;
const FormWrapper = tw.div`w-64 mx-auto mt-5 items-center flex flex-col`;
const Description = tw.p`mx-2 sm:mx-16`;

class ResetPasswordForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            emailValid: true,
            loading: false,
            redirect: undefined
        }
    }

    changeEmail = (e) => {
        this.setState({
            email: e.target.value,
            emailValid: true
        });
    }

    submit = () => {
        if (this.state.loading) {
            return;
        }

        if (!validateEmail(this.state.email)) {
            this.setState({
                emailValid: false
            });
            return;
        }

        this.setState({
            emailValid: true,
            loading: true
        });

        UserService.resetPassword(this.state.email)
            .then(resp => {
                this.setState({
                    email: "",
                    emailValid: true,
                    loading: false
                });
                toastSuccess(this.props.t("RESET_PASSWORD_SUCCESS"));
                this.setState({
                    redirect: "/login"
                });
            })
            .catch(err => {
                this.setState({
                    loading: false
                });
                toastError(this.props.t("ERROR_SERVER"))
            });
    }

    render() {
        if (this.state.redirect !== undefined) {
            return <Redirect to={this.state.redirect}/>
        }

        const {t} = this.props;

        return (
            <Page>
                <Container>
                    <Content>
                        <Logo src={logo} alt={"Logo"}/>
                        <Title>{t("RESET_PASSWORD_TITLE")}</Title>
                        <Description>{t("RESET_PASSWORD_DESCRIPTION")}</Description>
                        {this.state.loading && <Loader/>}
                        <FormWrapper>
                            <Input
                                placeholder={t("RESET_PASSWORD_PLACEHOLDER")}
                                type={"email"}
                                value={this.state.email}
                                onChange={this.changeEmail}
                                isInvalid={!this.state.emailValid}
                            />
                            <ButtonPurple
                                onClick={this.submit}
                                className={"mt-3"}
                            >
                                {t("RESET_PASSWORD_SUBMIT")}
                            </ButtonPurple>
                        </FormWrapper>
                    </Content>
                </Container>
            </Page>
        )
    }
}

export default connect(null, null)(withTranslation()(ResetPasswordForm));
