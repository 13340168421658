import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Redirect} from "react-router-dom";
import {RoundedButton} from "./common/Elements";
import tw from "twin.macro";
import {ReactComponent as BackIcon} from "feather-icons/dist/icons/arrow-left-circle.svg";
import UpdateSubPicture from "./subprofile/UpdateSubPicture";
import UpdateSubProfile from "./subprofile/UpdateSubProfile";
import UpdateSubPreferences from "./subprofile/UpdateSubPreferences";

const Content = tw.div`bg-white rounded-lg p-3 pt-10 lg:p-10 justify-center `;
const ProfileImg = tw.img`rounded-full h-40 w-40 mx-auto mb-3`;
const CoverImg = tw.img`rounded-lg mx-auto object-contain h-56`;
const ProfileDetails = tw.div``;
const Name = tw.div`text-purple-classic text-2xl my-5`;
const Text = tw.div``;
const Label = tw.div`text-xl mt-3`;

class SubProfile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            updatingPicture: false,
            updatingPictureType: "",
            updatingProfile: false,
            updatingPref: false,
            redirect: ""
        }
    }

    goBack = () => {
        if (this.state.updatingPicture) {
            this.setState({
                updatingPicture: false,
                updatingPictureType: ""
            });
        } else if (this.state.updatingProfile) {
            this.setState({
                updatingProfile: false
            });
        } else if (this.state.updatingPref) {
            this.setState({
                updatingPref: false
            });
        } else {
            this.setState({
                redirect: "/home"
            });
        }
    }

    updateImage = () => {
        this.setState({
            updatingPicture: true,
            updatingPictureType: "PROFILE"
        });
    }

    updateCover = () => {
        this.setState({
            updatingPicture: true,
            updatingPictureType: "COVER"
        });
    }

    updateProfile = () => {
        this.setState({
            updatingProfile: true
        });
    }

    updateJournal = () => {
        this.setState({
            updatingPref: true
        });
    }

    reloadProfile = () => (
        this.props.reloadCallback()
    );

    render() {
        if (this.state.redirect !== "") {
            return <Redirect to={this.state.redirect}/>
        }

        const {t} = this.props;
        const sub = this.props.subscriber;


        return (
            <Content>
                <BackIcon
                    onClick={this.goBack}
                    style={{height: 30, width: 30}}
                    className={"absolute top-2 left-2 text-purple-classic"}
                />
                {
                    this.state.updatingPicture ?
                        (
                            <UpdateSubPicture
                                type={this.state.updatingPictureType}
                                reloadCallback={this.reloadProfile}
                            />
                        ) : this.state.updatingProfile ? (
                            <UpdateSubProfile reloadCallback={this.reloadProfile}/>
                        ) : this.state.updatingPref ? (
                            <UpdateSubPreferences reloadCallback={this.reloadProfile}/>
                        ) : (
                            <div className={"text-center"}>
                                <Name>{t("SIGNUP_CIVILITY_" + sub.civility + "_PLACEHOLDER")} {sub.firstName} {sub.lastName}</Name>
                                <ProfileImg src={sub.image}/>
                                {sub.admin && <RoundedButton
                                    className={"my-3"}
                                    onClick={this.updateImage}>{t("SUB_UPDATE_PICTURE_BUTTON")}</RoundedButton>}
                                <CoverImg src={sub.coverImage}/>
                                {sub.admin && <RoundedButton
                                    className={"mt-3"}
                                    onClick={this.updateCover}>{t("SUB_UPDATE_PICTURE_BUTTON")}</RoundedButton>}
                                <ProfileDetails className={"mt-8"}>
                                    <Label>{t("PROFILE_ADDRESS_LABEL")}</Label>
                                    <Text>{sub.address}</Text>
                                    <Text>{sub.zipCode} {sub.city}</Text>
                                </ProfileDetails>
                                {sub.admin && <RoundedButton
                                    className={"mt-5"}
                                    onClick={this.updateProfile}>{t("SUB_UPDATE_PROFILE_BUTTON")}</RoundedButton>}
                                <div className={"mt-12"}>
                                    {sub.admin && <RoundedButton
                                        onClick={this.updateJournal}>{t("SUB_UPDATE_PROFILE_PREF_BUTTON")}</RoundedButton>}
                                </div>
                            </div>
                        )
                }

            </Content>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        language: state.profile.language,
        subscriber: state.currentSub !== undefined ? state.profile.subscribers[state.currentSub] : undefined,
    }
};

export default connect(mapStateToProps, null)(withTranslation()(SubProfile));
