import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {ButtonPurple, FormLine, FormWrapper, Input, Logo, Select, Title} from "../common/Elements";
import logo from "../../images/iris_logo_violet.png";
import logoPayment from "../../images/logo-stripe.png";
import {priceFormat} from "../../services/format";
import {ReactComponent as TagIcon} from "feather-icons/dist/icons/tag.svg";

import tw from "twin.macro";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js/pure";
import Constants from "../../Constants";
import PaymentService from "../../services/payment.service";
import StripeForm from "./StripeForm";
import Loader from "../common/Loader";
import {Redirect} from "react-router-dom";
import {setJwt, setSignupProcess} from "../../redux/actions";
import {toastError, toastSuccess} from "../common/Toast";
import {ReactComponent as BackIcon} from "feather-icons/dist/icons/arrow-left-circle.svg";
import {getClosureDates} from "../../services/tools";
import {getLanguage} from "../../services/languages";

const Total = tw.h2`text-center text-2xl text-purple-classic`;
const PromoCode = tw.div`mt-8 text-center`;
const PromoError = tw.div`text-red-500 text-lg`;
const PromoLabel = tw.a`w-full mx-auto md:w-96 pb-3 flex mx-auto`;
const PromoCodeInputWrapper = tw.div`w-full mx-auto md:w-96 mt-3`;
const CancelCode = tw.a`text-red-800`;

const ClosureChoice = tw.div`w-full mx-auto md:w-96 text-center items-center justify-center mt-5`;

const PaymentLabel = tw.div`text-center mb-3`;
const CardFormWrapper = tw.div`w-full mx-auto md:w-96 pb-8 mt-16`;
const NoPayment = tw.div`text-center my-5`;

const PaymentLogo = tw.img`w-64 mx-auto mt-24`;
const LoaderWrapper = tw.div`absolute rounded-lg bg-white bg-opacity-75 flex items-center justify-center left-0 top-0 z-20 w-full h-full`;

class PaymentForm extends React.Component {

    constructor(props) {
        super(props);

        let showClosureSelect;
        let fromMobileApp = false;

        if (this.props.showClosureSelect !== undefined) {
            // From mobile app
            showClosureSelect = this.props.showClosureSelect === "true";
            fromMobileApp = true;
        } else {
            // From website

            //Inscription OU renouvellement avec 0 magazine en cours => true
            if (this.props.signupProcess) {
                showClosureSelect = true;
            } else {
                const sub = this.props.profile.subscribers.find(sub => sub.inviteToken === this.props.subToken);
                if (sub === undefined) {
                    //Inscription d'un 2nd abonné
                    showClosureSelect = true;
                } else {
                    if (sub.paidMagazine === 0 || sub.paidMagazine === null || sub.paidMagazine === undefined) {
                        showClosureSelect = true;
                    } else {
                        showClosureSelect = false;
                    }
                }
            }
        }

        this.state = {
            currency: this.props.currency,
            formula: this.props.formula,
            subToken: this.props.subToken,
            firstMagazine: "NOW",
            promoCode: "",
            promoError: "",
            total: this.props.formula.amountWithVAT,
            totalDiscount: null,
            loading: false,
            redirect: "",
            showClosureSelect: showClosureSelect,
            fromMobileApp: fromMobileApp
        };

        const closureDates = getClosureDates();
        const dateOption = {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
        };

        this.closureNow = new Date(closureDates[0]).toLocaleDateString(getLanguage(), dateOption);
        this.closureNext = new Date(closureDates[1]).toLocaleDateString(getLanguage(), dateOption);

        this.stripePromise = loadStripe(Constants.stripePublicKey);
    }

    componentDidMount() {
        if (this.state.fromMobileApp) {
            this.initJwtFromMobile();
        }
    }

    initJwtFromMobile() {
        if (typeof window.magirisjwt !== "undefined") {
            this.props.updateJwt(window.magirisjwt);
        } else {
            setTimeout(() => this.initJwtFromMobile(), 1000);
        }
    }

    cancelCode = () => {
        this.setState({
            promoCode: "",
            promoError: "",
            totalDiscount: null
        });
    }

    updateClosureSelect = (val) => {
        this.setState({
            firstMagazine: val.target.value
        });
    }

    applyPromo = () => {
        const {t} = this.props;
        PaymentService.getCodePromoInfo(this.state.promoCode)
            .then(res => {
                const code = res.data;
                if (!code.available) {
                    this.setState({
                        promoError: t("PAYMENT_PROMO_NOT_AVAIL")
                    })
                } else {
                    if (code.minAmount !== null && code.minAmount > this.state.total) {
                        this.setState({
                            promoError: t("PAYMENT_PROMO_NOT_EXIST")
                        })
                    } else {
                        let newPrice = "";
                        if (code.promoType === "ABSOLUTE") {
                            newPrice = this.state.total - code.amount;
                            newPrice = Math.round(newPrice * 100) / 100;
                            if (newPrice < 0) {
                                newPrice = 0;
                            }
                        } else {
                            newPrice = this.state.total - (this.state.total * code.amount / 100);
                            newPrice = Math.round(newPrice * 100) / 100;
                        }
                        this.setState({
                            promoError: "",
                            totalDiscount: newPrice
                        })
                    }
                }
            })
            .catch(err => {
                this.setState({
                    promoError: t("PAYMENT_PROMO_NOT_EXIST")
                })
            })
    }

    setLoading = (bool) => {
        this.setState({
            loading: bool
        })
    }

    pay = () => {
        this.setState({
            loading: true
        }, async () => {
            try {
                const response = await this.callBackend();
                if (response.needPayment) {
                    toastError(this.props.t("PAYMENT_ERROR"))
                    this.setState({
                        loading: false
                    });
                } else {
                    this.redirectAfterPayment();
                }
            } catch (e) {
                this.setState({
                    loading: false
                });
                toastError(this.props.t("ERROR_SERVER"))
            }
        });
    }

    callBackend = () => {
        const payload = {
            formulaID: this.state.formula.formulaId,
            paymentService: "STRIPE",
            promoCode: this.state.promoCode,
            subscriberToken: this.state.subToken,
            firstMagazine: this.state.firstMagazine
        };
        return PaymentService.createTransaction(payload);
    }

    stripeOk = () => {
        this.redirectAfterPayment();
    }

    redirectAfterPayment = () => {
        toastSuccess(this.props.t("PAYMENT_SUCCESS"));
        if (window.ReactNativeWebView !== undefined) {
            window.ReactNativeWebView.postMessage("PAYMENT_SUCCESS");
        }
        if (window.snaptr !== undefined) {
            window.snaptr('track', 'SUBSCRIBE');
        }
        setTimeout(() => {
            this.props.setSignupProcessEnd();
            this.setState({
                redirect: "/home/" + this.state.subToken
            });
        }, 5000)
    }

    stripeError = () => {
        this.setState({
            loading: false,
        });
        toastError(this.props.t("PAYMENT_ERROR"))
    }

    render() {

        if (this.state.redirect.length > 0) {
            return <Redirect to={this.state.redirect}/>
        }

        const {t} = this.props;

        let title = t("PAYMENT_RESUME");
        title = title.replace("%FORMULA%", t(this.state.formula.name))

        return (
            <FormWrapper>
                <BackIcon
                    onClick={this.props.goBack}
                    style={{height: 30, width: 30}}
                    className={"absolute top-2 left-2 text-purple-classic"}
                />
                {this.state.loading && <LoaderWrapper><Loader/></LoaderWrapper>}
                <Logo src={logo} alt={"Logo"}/>
                <Title>{title}</Title>
                {
                    this.state.totalDiscount === null ? (
                        <Total>{t("PAYMENT_TOTAL") + " " + priceFormat(this.state.currency, this.state.total)}</Total>
                    ) : (
                        <div>
                            <Total
                                className={"line-through"}
                            >
                                {t("PAYMENT_TOTAL") + " " + priceFormat(this.state.currency, this.state.total)}
                            </Total>
                            <Total>{t("PAYMENT_TOTAL") + " " + priceFormat(this.state.currency, this.state.totalDiscount)}</Total>
                        </div>
                    )
                }
                {
                    this.state.showClosureSelect && <ClosureChoice>
                        <FormLine>
                            {t("SIGNUP_S2_MAGZINE_DATE_LABEL")}
                        </FormLine>
                        <FormLine className={"mt-2"}>
                            <Select
                                onChange={this.updateClosureSelect}
                                value={this.state.firstMagazine}
                            >
                                <option value={"NOW"}>
                                    {this.closureNow}
                                </option>
                                <option value={"NEXTMONTH"}>
                                    {this.closureNext}
                                </option>
                            </Select>
                        </FormLine>
                    </ClosureChoice>
                }

                <PromoCode>
                    {this.state.promoError && <PromoError>{this.state.promoError}</PromoError>}
                    {
                        this.state.totalDiscount === null ? (
                            <div>
                                <PaymentLabel>
                                    {t("PAYMENT_PROMO_LABEL")}
                                </PaymentLabel>
                                <PromoLabel
                                    onClick={this.promoToggle}
                                >
                                    <TagIcon style={{height: 40, width: 40}} className="mt-4 mr-2"/>
                                    <PromoCodeInputWrapper>
                                        <Input
                                            placeholder={t("PAYMENT_PROMO_PLACEHOLDER")}
                                            value={this.state.promoCode}
                                            onChange={(e) => {
                                                this.setState({
                                                    promoCode: e.target.value
                                                })
                                            }}
                                        />
                                        {
                                            this.state.promoCode.length > 1 ? (
                                                <ButtonPurple
                                                    className={"float-right"}
                                                    onClick={this.applyPromo}
                                                >
                                                    {t("PAYMENT_PROMO_SUBMIT")}
                                                </ButtonPurple>
                                            ) : null
                                        }
                                    </PromoCodeInputWrapper>
                                </PromoLabel>
                            </div>
                        ) : (
                            <div>
                                <h2>{t("PAYMENT_PROMO_CODE_APPLY") + " " + this.state.promoCode}</h2>
                                <CancelCode
                                    onClick={this.cancelCode}
                                >
                                    {t("PAYMENT_PROMO_CODE_CANCEL")}
                                </CancelCode>
                            </div>
                        )
                    }
                </PromoCode>
                {
                    this.state.totalDiscount !== null && this.state.totalDiscount === 0 ? (
                        <NoPayment>
                            <ButtonPurple
                                className={"mb-5"}
                                onClick={this.pay}
                            >
                                {t("PAYMENT_FREE_LABEL")}
                            </ButtonPurple>
                        </NoPayment>
                    ) : (
                        <CardFormWrapper>
                            <PaymentLabel>
                                {t("PAYMENT_SECURE_LABEL")}
                            </PaymentLabel>
                            <Elements stripe={this.stripePromise}>
                                <StripeForm
                                    key={this.state.totalDiscount !== null ? this.state.promoCode : "noPromo"}
                                    customer={this.props.profile}
                                    stripe={this.stripePromise}
                                    paymentIntentFnc={this.callBackend}
                                    setLoading={this.setLoading}
                                    paymentSuccessful={this.stripeOk}
                                    paymentError={this.stripeError}
                                />
                            </Elements>
                            <PaymentLogo src={logoPayment} alt={"Payment logo"}/>
                        </CardFormWrapper>
                    )
                }
            </FormWrapper>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateJwt: (jwt) => dispatch(setJwt(jwt)),
        setSignupProcessEnd: () => dispatch(setSignupProcess(false)),
    }
};


const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        signupProcess: state.signupProcess
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PaymentForm));
