import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {ButtonFull, FormLine, InputCheckbox, InputLabel, SignupStepWrapper} from "../common/Elements";

import Constants from "../../Constants";
import {toastError} from "../common/Toast";
import UserService from "../../services/user.service";

class SignupStep4 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            newsletter: false,
            cgv: false,
            userAuthenticated: UserService.isAuthenticated()
        }
    }

    handleChange = (evt) => {
        const old = this.state[evt.target.name];
        this.setState({
            [evt.target.name]: !old
        });
    }

    handleSubmit = (fields) => {
        const payload = {
            newsletter: this.state.newsletter,
            cgv: this.state.cgv,
        };

        if (payload.cgv || this.state.userAuthenticated) {
            this.props.handleSubmit(4, payload);
        } else {
            toastError(this.props.t("SIGNUP_STEP4_CONDITIONS"));
        }
    }


    render() {
        const {t} = this.props;

        return (
            <SignupStepWrapper className={!this.props.isActive && "opacity-50"}>
                {!this.state.userAuthenticated ? (
                    <div>
                        <FormLine className={"mt-3"}>
                            <InputCheckbox
                                type={"checkbox"}
                                name={"newsletter"}
                                onChange={this.handleChange}
                                checked={this.state.newsletter}
                                id={"newsletterCheckbox"}
                            />
                            <InputLabel
                                htmlFor={"newsletterCheckbox"}>
                                {t("SIGNUP_NEWSLETTER_LABEL")}
                            </InputLabel>
                        </FormLine>
                        <div className={"mt-1"}>
                            <InputCheckbox
                                type={"checkbox"}
                                name={"cgv"}
                                onChange={this.handleChange}
                                checked={this.state.cgv}
                                id={"cgvCheckbox"}
                            />
                            <InputLabel
                                className={"sm:ml-5 sm:ml-2"}
                                htmlFor={"cgvCheckbox"}
                            >
                                {t("SIGNUP_CGV_LABEL")}
                            </InputLabel>
                            <a href={Constants.cgvUrl} target={"_blank"}
                               className={"text-purple-classic"}>{t("SIGNUP_STEP4_CGV")} </a>
                            , <a href={Constants.cguUrl} target={"_blank"}
                                 className={"text-purple-classic"}> {t("SIGNUP_STEP4_CGU")} </a>
                            & <a href={Constants.confidentialiteUrl} target={"_blank"}
                                 className={"text-purple-classic"}> {t("SIGNUP_STEP4_CONF")}</a>
                        </div>
                    </div>
                ) : null}

                {this.props.isActive && <ButtonFull onClick={this.handleSubmit}>
                    <span className={"text-xl"}>{t("SIGNUP_STEP4_SUBMIT")}</span>
                    {!this.props.isInvitation && <span className={"text-sm"}>{t("SIGNUP_STEP4_SUBMIT_DESC")}</span>}
                </ButtonFull>}
            </SignupStepWrapper>
        )

    }

}

export default connect(null, null)(withTranslation()(SignupStep4));
