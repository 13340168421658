class Rotator {

    static rotateImage(image, srcOrientation) {
        const width = image.width,
            height = image.height,
            sourceBase64 = image.src,
            canvas = document.createElement('canvas'),
            ctx = canvas.getContext("2d");

        // set proper canvas dimensions before transform & export
        canvas.width = width;
        canvas.height = height;

        // transform context before drawing image
        switch (srcOrientation) {
            case 2:
                ctx.transform(-1, 0, 0, 1, width, 0);
                ctx.translate(width, 0);
                ctx.scale(-1, 1);
                break;
            case 3:
                ctx.transform(-1, 0, 0, -1, width, height);
                ctx.translate(width, height);
                ctx.rotate(Math.PI);
                break;
            case 4:
                ctx.transform(1, 0, 0, -1, 0, height);
                ctx.translate(0, height);
                ctx.scale(1, -1);
                break;
            case 5:
                ctx.transform(0, 1, 1, 0, 0, 0);
                ctx.rotate(-0.5 * Math.PI);
                ctx.scale(-1, 1);
                break;
            case 6:
                ctx.transform(0, 1, -1, 0, width, 0);
                ctx.rotate(-0.5 * Math.PI);
                ctx.translate(-width, 0);
                break;
            case 7:
                ctx.transform(0, -1, -1, 0, width, height);
                ctx.rotate(-0.5 * Math.PI);
                ctx.translate(-width, height);
                ctx.scale(1, -1);
                break;
            case 8:
                ctx.transform(0, -1, 1, 0, 0, height);
                ctx.rotate(0.5 * Math.PI);
                ctx.translate(0, -height);
                break;
            default:
                break;
        }
        // draw image
        ctx.drawImage(image, 0, 0, width, height);
        // export base64
        return canvas.toDataURL(`image/${'JPEG'}`, 100);
    }

    static b64toBlob(b64Data) {
        const contentType = 'image/jpeg';
        const sliceSize = 512;

        const byteCharacters = atob(b64Data.toString().replace(/^data:image\/(jpeg|jpg);base64,/, ''));
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: contentType});
    }

    static getOrientation(file, callback) {
        const reader = new FileReader();

        reader.onload = function (event) {
            const view = new DataView(event.target.result);

            if (view.getUint16(0, false) != 0xFFD8) return callback(-2);

            let length = view.byteLength,
                offset = 2;

            while (offset < length) {
                const marker = view.getUint16(offset, false);
                offset += 2;

                if (marker == 0xFFE1) {
                    if (view.getUint32(offset += 2, false) != 0x45786966) {
                        return callback(-1);
                    }
                    const little = view.getUint16(offset += 6, false) == 0x4949;
                    offset += view.getUint32(offset + 4, little);
                    const tags = view.getUint16(offset, little);
                    offset += 2;

                    for (var i = 0; i < tags; i++)
                        if (view.getUint16(offset + (i * 12), little) == 0x0112)
                            return callback(view.getUint16(offset + (i * 12) + 8, little));
                } else if ((marker & 0xFF00) != 0xFF00) break;
                else offset += view.getUint16(offset, false);
            }
            return callback(-1);
        };

        reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
    };

    static createRotatedImage(file, outputType = 'base64', responseUriFunc) {
        let blob = null;
        let rotatedDataUrl = null;
        const reader = new FileReader();
        if (file) {
            if (file.type && !file.type.includes("image")) {
                throw Error("File Is NOT Image!");
            } else {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const image = new Image();
                    image.src = reader.result;
                    image.onload = function () {
                        Rotator.getOrientation(file, function (orientation) {
                            try {
                                rotatorFunction(orientation, image);
                            } catch (e) {
                                rotatorFunction(1, image);
                            }
                        });
                    };

                    function rotatorFunction(imageOrientation, image) {
                        if (imageOrientation == -2) {
                            throw Error("Image Is NOT JPEG!");
                        } else if (imageOrientation == -1) {
                            throw Error("Not Defined!");
                        }
                        rotatedDataUrl = Rotator.rotateImage(image, imageOrientation);
                        blob = Rotator.b64toBlob(rotatedDataUrl);
                        outputType === 'blob' ?
                            responseUriFunc(blob)
                            :
                            responseUriFunc(rotatedDataUrl)
                    }
                };
                reader.onerror = error => {
                    throw Error(error);
                };
            }
        } else {
            throw Error("File Not Found!");
        }
    }
}

export default {
    createRotatedImage: (file, outputType, responseUriFunc) => {
        return Rotator.createRotatedImage(file, outputType, responseUriFunc)
    }
}