import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Button, Input, LinkStyle, Page, Title} from "./common/Elements";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import logo from "../images/iris_logo_violet.png";
import UserService from "../services/user.service";
import Loader from "./common/Loader";
import {Link, Redirect} from "react-router-dom";
import {login} from "../redux/actions";
import loginImage from "../images/login-img.jpg";

const Container = tw.div`p-5 h-screen sm:pt-10v`;
const Content = tw.div`sm:h-80v sm:w-9/12 sm:mx-auto rounded-lg bg-white`;
const TwoColumn = tw.div`flex`;
const Left = tw.div`w-full lg:w-4/12 text-center px-10 my-auto`;

const Right = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`lg:w-8/12 h-80v bg-cover bg-center rounded-tr-lg rounded-br-lg`}
`;

const Logo = tw.img`w-40 mx-auto mt--24`;
const Separator = tw.div`mt-3`;
const Error = tw.div`text-center font-medium text-red-500 pb-8 pt-5`;

const SignupRedirect = tw.div`mt-8 text-sm`
const ForgotPassword = tw.div`text-xs text-right mt--1 text-purple-classic hover:text-purple-pastel`;

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: false,
            submiting: false,
            email: "",
            password: "",
            fromPath: props.location.state?.from?.pathname,
            isAccountValid: UserService.isAccountValid()
        };
    }

    updateField = (field, value) => {
        this.setState({[field]: value});
    }

    sumbitForm = (e) => {
        e.preventDefault();

        if (this.state.submiting) {
            return;
        }

        this.setState({
            submiting: true
        });

        UserService
            .login(this.state.email, this.state.password)
            .then(r => {
                const responseData = r.data;
                let profile = {...responseData};
                delete profile.jwt;

                this.props.login(profile, responseData.jwt);
                this.setState({
                    isAccountValid: true
                })
            }).catch(e => {
                this.setState({
                    submiting: false,
                    error: true
                });
            }
        );

    }

    render() {

        const {t} = this.props;

        if (this.state.isAccountValid) {
            if (this.state.fromPath !== undefined) {
                return <Redirect to={this.state.fromPath}/>
            }
            return <Redirect to="/home"/>
        }

        return (
            <Page>
                <Container>
                    <Content>
                        <TwoColumn>
                            <Left>
                                <Logo src={logo} alt={"Logo"}/>
                                <Title>{t("LOGIN_TITLE")}</Title>
                                {
                                    this.state.error ? (
                                        <Error>{t("LOGIN_ERROR")}</Error>
                                    ) : this.state.submiting ? (
                                        <Loader/>
                                    ) : (
                                        <Separator/>
                                    )
                                }
                                <form onSubmit={this.sumbitForm}>
                                    <Input
                                        autocomplete="email"
                                        placeholder={t("LOGIN_EMAIL_PLACEHOLDER")}
                                        type="email"
                                        onChange={(val) => this.updateField('email', val.target.value)}
                                        value={this.state.email}
                                        required
                                    />
                                    <Input
                                        autocomplete="current-password"
                                        placeholder={t("LOGIN_PASSWORD_PLACEHOLDER")}
                                        type="password"
                                        name="password"
                                        onChange={(val) => this.updateField('password', val.target.value)}
                                        value={this.state.password}
                                        required
                                    />
                                    <ForgotPassword>
                                        <Link to={"/resetpassword"}> {t("LOGIN_FORGOT_PASSWORD")}</Link>
                                    </ForgotPassword>
                                    {
                                        this.state.submiting ? (
                                            <Button type="submit" disabled>
                                                {t("LOGIN_SUBMIT_BUTTON")}
                                            </Button>
                                        ) : (
                                            <Button type="submit">
                                                {t("LOGIN_SUBMIT_BUTTON")}
                                            </Button>
                                        )
                                    }
                                </form>
                                <SignupRedirect>
                                    {t("LOGIN_NO_ACCOUNT")}
                                    <Link to={"/signup"}><LinkStyle>{t("LOGIN_JOIN_US")}</LinkStyle></Link>
                                </SignupRedirect>
                            </Left>
                            <Right
                                imageSrc={loginImage}/>
                        </TwoColumn>
                    </Content>
                </Container>
            </Page>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        login: (profile, jwt) => dispatch(login(profile, jwt))
    }
};

export default connect(null, mapDispatchToProps)(withTranslation()(Login));
