import Constants from "../Constants";
import {store} from "../redux/store";

const axios = require('axios');

class SubscriptionService {

    getSubscriberName(token) {
        return axios
            .get(Constants.baseApiUrl + Constants.getSubscriberName + token);
    }

    createCustomer(customer) {
        return axios
            .put(
                Constants.baseApiUrl + Constants.createCustomer,
                customer,
            )
    }

    createSubscriber(subscriber, token) {
        return axios
            .put(
                Constants.baseApiUrl + Constants.createSubscriber,
                subscriber,
                {headers: {Authorization: `Bearer ${token}`}}
            )
    }

    associate(payload) {
        return axios
            .put(
                Constants.baseApiUrl + Constants.associateSubscriber,
                payload,
                this.getHeaders()
            )
    }

    getFormulas(country, currency) {
        return axios
            .get(Constants.baseApiUrl + Constants.getFormulas + country + "/" + currency)
    }

    getHeaders() {
        const token = store.getState().jwt;
        return {
            headers: {Authorization: `Bearer ${token}`}
        };
    }

}

export default new SubscriptionService();
