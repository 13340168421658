import tw from "twin.macro";
import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import MagazineService from "../../services/magazine.service";
import {toastError} from "../common/Toast";
import Loader from "../common/Loader";
import {TitleBis} from "../common/Elements";
import magazinePastel from '../../images/magazine_pastel.png';

const Container = tw.div`bg-white rounded-lg px-8 pb-8 text-center text-purple-pastel`;
const Line = tw.a`flex items-center mb-3 ml-5`;
const MagazineIcone = tw.img`h-10`;
const MagazineText = tw.div`ml-5`;


class History extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            magazines: [],
        };

        this.dateOption = {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        };
    }

    componentDidMount() {
        this.loadOldMagazine();
    }

    loadOldMagazine = () => {
        MagazineService.loadOldMagazines(this.props.subscriber.subscriberId)
            .then(resp => {
                this.setState({
                    magazines: resp.data,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                });
                toastError(this.props.t("ERROR_SERVER"));
            });
    };

    render() {

        const {t} = this.props;

        return (
            <Container>
                <TitleBis className={'mb-8'}>
                    {t("HOME_MENU_HISTORY")}
                </TitleBis>
                {
                    this.state.loading ? (
                        <Loader/>
                    ) : this.state.magazines.length === 0 ? (
                        <div>{t('HISTORY_EMPTY')}</div>
                    ) : (
                        <div>
                            {
                                this.state.magazines.map(mag => {
                                    const dateStr = new Date(mag.endDate).toLocaleDateString(this.props.language, this.dateOption);
                                    return (
                                        <Line href={mag.path} target={"_blank"}>
                                            <MagazineIcone src={magazinePastel} alt={"Magazine icon"}/>
                                            <MagazineText>
                                                {t('HOME_MAGAZINE_SELECT_PREFIX') + ' '}
                                                {t('HOME_MAGAZINE_SELECT_DATE_PREFIX') + ' '}
                                                {dateStr}
                                            </MagazineText>
                                        </Line>
                                    );
                                })
                            }
                        </div>
                    )
                }
            </Container>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        language: state.profile.language,
        subscriber: state.currentSub !== undefined ? state.profile.subscribers[state.currentSub] : undefined,
    }
};

export default connect(mapStateToProps, null)(withTranslation()(History));
