import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {
    Button,
    FormLine,
    FormSepartor,
    FormWrapper,
    Input, InputEmpty,
    InputWrapper,
    Select,
    SubmitWrapper,
    Title
} from "../common/Elements";
import {Formik} from "formik";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import Constants from "../../Constants";
import {CountryDropdown} from "react-country-region-selector";
import UserService from "../../services/user.service";
import {Redirect} from "react-router-dom";
import {toastError, toastSuccess} from "../common/Toast";
import {setJwt, setProfile} from "../../redux/actions";
import Loader from "../common/Loader";
import tw from "twin.macro";

const LoaderWrapper = tw.div`absolute rounded-lg bg-white bg-opacity-75 pt-12 left-0 top-0 z-20 text-center w-full h-full`;

class UpdateProfile extends React.Component {

    constructor(props) {
        super(props);

        const {t} = this.props;

        this.state = {
            submitAlreadyClick: false,
            redirect: "",
            submiting: false
        };

        this.schema = yup.object({
            firstName: yup.string().min(2).required(),
            lastName: yup.string().min(2).required(),
            email: yup.string().email().required(),
            phone1: yup.string().min(2).required(),
            phone2: yup.string().nullable().notRequired(),
            address: yup.string().required(),
            zipCode: yup.string().required(),
            city: yup.string().required(),
            country: yup.string().required(),
            canton: yup.string().nullable().when("country", {
                is: "CH",
                then: yup.string().required()
            }),
            language: yup.string().required(),
            oldPassword: yup.string(),
            newPassword: yup.string().when("oldPassword", {
                is: (oldPassword) => oldPassword !== undefined && oldPassword.length > 0,
                then: yup.string().required(" ") // /!\ Do not remove space
                    .min(8, t("SIGNUP_PASSWORD_SIZE_ERROR"))
                    .max(30, t("SIGNUP_PASSWORD_SIZE_ERROR"))
                    .matches(/[a-z]/, t("SIGNUP_PASSWORD_LOWER_ERROR"))
                    .matches(/[A-Z]/, t("SIGNUP_PASSWORD_UPPER_ERROR"))
                    .matches(/[0-9]/, t("SIGNUP_PASSWORD_NUMBER_ERROR"))
            })
        });
    }

    clickSubmit = () => {
        this.setState({
            submitAlreadyClick: true
        });
    }

    handleSubmit = (values) => {
        this.setState({
            submiting: true,
        });
        UserService.updateCusProfile(values)
            .then(resp => {
                this.props.updateProfile(resp.data);
                this.props.updateJwt(resp.data.jwt);
                toastSuccess(this.props.t("PROFILE_UPDATE_PROFILE_SUCCESS"))
                this.props.reloadCallback();
                this.setState({
                    submiting: false,
                    redirect: "/home"
                });
            })
            .catch(err => {
                this.setState({
                    submiting: false,
                });
                if (err.response !== undefined && err.response.status !== 500 && err.response.data) {
                    toastError(this.props.t("PROFILE_UPDATE_ERR_" + err.response.data));
                } else {
                    toastError(this.props.t("ERROR_SERVER"));
                }
            });
    }

    render() {
        if (this.state.redirect !== "") {
            return <Redirect to={this.state.redirect}/>
        }

        const {t} = this.props;

        //DUPLICATION WHITH STEP1
        return (
            <FormWrapper>
                <Title className={"mb-5"}>{t("PROFILE_UPDATE_PROFILE_TITLE")}</Title>
                {this.state.submiting && <LoaderWrapper><Loader/></LoaderWrapper>}
                <Formik
                    validationSchema={this.schema}
                    onSubmit={this.handleSubmit}
                    initialValues={this.props.profile}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                          isSubmitting,
                          setValues
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <FormLine>
                                <Select
                                    name={"civility"}
                                    value={values.civility || ''}
                                    onChange={handleChange}
                                >
                                    <option value="MR">{t("SIGNUP_CIVILITY_MR_PLACEHOLDER")}</option>
                                    <option value="MS">{t("SIGNUP_CIVILITY_MS_PLACEHOLDER")}</option>
                                    <option value="BOTH">{t("SIGNUP_CIVILITY_BOTH_PLACEHOLDER")}</option>
                                </Select>
                                <Input
                                    placeholder={t("SIGNUP_FIRSTNAME_PLACEHOLDER")}
                                    name={"firstName"}
                                    value={values.firstName || ''}
                                    onChange={handleChange}
                                    isInvalid={this.state.submitAlreadyClick && errors.firstName}
                                />
                                <Input
                                    placeholder={t("SIGNUP_LASTNAME_PLACEHOLDER")}
                                    name={"lastName"}
                                    value={values.lastName || ''}
                                    onChange={handleChange}
                                    isInvalid={this.state.submitAlreadyClick && errors.lastName}
                                />
                            </FormLine>
                            <FormLine>
                                <Input
                                    placeholder={t("SIGNUP_EMAIL_PLACEHOLDER")}
                                    name={"email"}
                                    type={"email"}
                                    value={values.email || ''}
                                    onChange={handleChange}
                                    isInvalid={this.state.submitAlreadyClick && errors.email}
                                />
                            </FormLine>
                            <FormLine>
                                <PhoneInput
                                    inputProps={{name: "phone1"}}
                                    value={values.phone1 || ''}
                                    onChange={
                                        (value, country, e, formattedValue) => {
                                            handleChange(e)
                                        }
                                    }
                                    enableSearch={true}
                                    isValid={!(this.state.submitAlreadyClick && errors.phone1)}
                                    onlyCountries={Constants.countriesAvailable}
                                    preferredCountries={Constants.defaultCountries}
                                    country={"ch"}
                                    searchPlaceholder={t("SIGNUP_PHONE_SEARCH_PLACEHOLDER")}
                                    specialLabel={t("SIGNUP_PHONE1_PLACEHOLDER")}
                                />
                                <PhoneInput
                                    inputProps={{name: "phone2"}}
                                    value={values.phone2 || ''}
                                    onChange={
                                        (value, country, e, formattedValue) => {
                                            handleChange(e)
                                        }
                                    }
                                    enableSearch={true}
                                    isValid={!(this.state.submitAlreadyClick && errors.phone2)}
                                    onlyCountries={Constants.countriesAvailable}
                                    preferredCountries={Constants.defaultCountries}
                                    country={"ch"}
                                    searchPlaceholder={t("SIGNUP_PHONE_SEARCH_PLACEHOLDER")}
                                    specialLabel={t("SIGNUP_PHONE2_PLACEHOLDER")}
                                />
                            </FormLine>
                            <FormSepartor/>
                            <FormLine>
                                <Input
                                    placeholder={t("SIGNUP_ADDRESS_PLACEHOLDER")}
                                    name={"address"}
                                    value={values.address || ''}
                                    onChange={handleChange}
                                    isInvalid={this.state.submitAlreadyClick && errors.address}
                                />
                            </FormLine>
                            <FormLine>
                                <Input
                                    placeholder={t("SIGNUP_ZIPCODE_PLACEHOLDER")}
                                    name={"zipCode"}
                                    value={values.zipCode || ''}
                                    onChange={handleChange}
                                    isInvalid={this.state.submitAlreadyClick && errors.zipCode}
                                />
                                <Input
                                    placeholder={t("SIGNUP_CITY_PLACEHOLDER")}
                                    name={"city"}
                                    value={values.city || ''}
                                    onChange={handleChange}
                                    isInvalid={this.state.submitAlreadyClick && errors.city}
                                />
                            </FormLine>
                            <FormLine>
                                <InputWrapper isInvalid={this.state.submitAlreadyClick && errors.country}>
                                    <CountryDropdown
                                        className={"bg-white appearance-none"}
                                        style={{width: "100%", outline: "none"}}
                                        value={values.country || ''}
                                        name={"country"}
                                        valueType={"short"}
                                        onChange={(val, e) => {
                                            handleChange(e);
                                        }}
                                        whitelist={Constants.countriesAvailableUpper}
                                        priorityOptions={Constants.defaultCountriesUpper}
                                        defaultOptionLabel={t("SIGNUP_COUNTRY_PLACEHOLDER")}
                                    />
                                </InputWrapper>
                                {
                                    values.country === "CH" ? (
                                        <Select
                                            isInvalid={this.state.submitAlreadyClick && errors.canton}
                                            name={"canton"}
                                            value={values.canton || 'default'}
                                            onChange={handleChange}
                                        >
                                            <option
                                                value="default"
                                                disabled
                                            >
                                                {t("SIGNUP_CANTON_PLACEHOLDER")}
                                            </option>
                                            {Object.keys(Constants.cantons).map(key => {
                                                return (
                                                    <option
                                                        key={key}
                                                        value={key}
                                                    >
                                                        {Constants.cantons[key]}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    ) : (
                                        <InputEmpty/>
                                    )
                                }
                            </FormLine>
                            <FormSepartor/>
                            <FormLine>
                                <Select
                                    isInvalid={this.state.submitAlreadyClick && errors.language}
                                    name={"language"}
                                    value={values.language}
                                    onChange={handleChange}
                                >
                                    {Object.keys(Constants.languages).map(key => {
                                        return (
                                            <option
                                                key={key}
                                                value={key}
                                            >
                                                {Constants.languages[key]}
                                            </option>
                                        );
                                    })}
                                </Select>
                                <InputEmpty/>
                            </FormLine>
                            <FormSepartor/>
                            <FormLine className="text-red-500 ml-1 mb-1">
                                {
                                    errors.newPassword ? errors.newPassword : ""
                                }
                            </FormLine>
                            <FormLine>
                                <Input
                                    placeholder={t("PROFILE_UPDATE_PROFILE_OLDPASS_PLACHOLDER")}
                                    name={"oldPassword"}
                                    type={"password"}
                                    value={values.oldPassword || ''}
                                    onChange={handleChange}
                                    isInvalid={this.state.submitAlreadyClick && errors.oldPassword}
                                />
                                <Input
                                    placeholder={t("PROFILE_UPDATE_PROFILE_NEWPASS_PLACHOLDER")}
                                    name={"newPassword"}
                                    type={"password"}
                                    value={values.newPassword || ''}
                                    onChange={handleChange}
                                    isInvalid={this.state.submitAlreadyClick && errors.newPassword}
                                />
                            </FormLine>
                            <SubmitWrapper>
                                <Button
                                    onClick={() => this.clickSubmit(isValid)}
                                    type={"submit"}
                                >
                                    {t("PROFILE_UPDATE_PROFILE_SUBMIT")}
                                </Button>
                            </SubmitWrapper>
                        </form>
                    )}
                </Formik>
            </FormWrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.profile
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateProfile: (profile) => dispatch(setProfile(profile)),
        updateJwt: (jwt) => dispatch(setJwt(jwt)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UpdateProfile));
