import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {
    Button,
    FormLine,
    FormSepartor,
    FormWrapper, Input, InputCheckbox, InputLabel,
    InputRadio, Select,
    SubmitWrapper,
    Title
} from "../common/Elements";
import Loader from "../common/Loader";
import {Formik} from "formik";
import tw from "twin.macro";
import Constants from "../../Constants";
import UserService from "../../services/user.service";
import {toastError, toastSuccess} from "../common/Toast";
import {Redirect} from "react-router-dom";

const Details = tw.p`text-justify mb-5`;
const Label = tw.h3`text-purple-classic font-medium mb-3`;
const SubChoice = tw.div`ml-8`;
const LoaderWrapper = tw.div`absolute rounded-lg bg-white bg-opacity-75 pt-12 left-0 top-0 z-20 text-center w-full h-full`;

class UpdateSubPreferences extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            submitAlreadyClick: false,
            redirect: "",
            submiting: false
        };

        this.internationalChoices = {
            "EUROPE": [
                "FRANCE",
                "GERMANY",
                "BRITAIN",
                "ITALY",
                "SPAIN",
                "OTHER"
            ],
            "NORTHAMERICA": [
                "USA",
                "CANADA"
            ],
            "AMERICA": [
                "OTHER"
            ],
            "ASIA": [
                "CHINA",
                "INDIA",
                "JAPAN",
                "OTHER"
            ],
            "AFRICA": [
                "OTHER"
            ],
            "OCEANIA": [
                "OTHER"
            ],
        };

        this.nationalChoices = [
            "POLITICS",
            "ECONOMY",
            "SOCIAL"
        ];
    }

    handleSubmit = (values) => {
        this.setState({
            submiting: true,
        });

        UserService.updateSubPreferences(values, this.props.subscriber.subscriberId)
            .then(resp => {
                toastSuccess(this.props.t("PROFILE_UPDATE_PROFILE_SUCCESS"))
                this.props.reloadCallback();
                this.setState({
                    submiting: false,
                    redirect: "/home"
                });
            })
            .catch(err => {
                this.setState({
                    submiting: false,
                });
                toastError(this.props.t("ERROR_SERVER"));
            });
    }

    clickSubmit = () => {
        this.setState({
            submitAlreadyClick: true
        });
    }

    render() {
        if (this.state.redirect !== "") {
            return <Redirect to={this.state.redirect}/>
        }

        const {t} = this.props;

        let initialValues = {
            international: "REDAC",
            national: "REDAC",
            local: "REDAC",
        };

        if (this.props.subscriber.magazinePref !== null) {
            initialValues = this.props.subscriber.magazinePref;
        }

        return (
            <FormWrapper>
                <Title className={"mb-5"}>{t("SUB_UPDATE_PREFERENCES_TITLE")}</Title>
                <Details>
                    {t("SUB_UPDATE_PREFERENCES_CONTENT")}
                </Details>
                {this.state.submiting && <LoaderWrapper><Loader/></LoaderWrapper>}
                <Formik
                    onSubmit={this.handleSubmit}
                    initialValues={initialValues}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                          isSubmitting,
                          setValues
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <FormLine>
                                <Label>{t("SUB_UPDATE_PREFERENCES_LABEL_START") + t("SUB_UPDATE_PREFERENCES_LABEL_INTERNATIONAL")}</Label>
                            </FormLine>
                            <FormLine>
                                <InputRadio
                                    type={"radio"}
                                    name={"international"}
                                    value={"REDAC"}
                                    onChange={handleChange}
                                    checked={values.international === "REDAC"}
                                    id={"international-REDAC"}
                                />
                                <InputLabel htmlFor="international-REDAC">
                                    {t("SUB_UPDATE_PREFERENCES_REDAC_VALUE")}
                                </InputLabel>
                            </FormLine>
                            <FormLine>
                                <InputRadio
                                    type={"radio"}
                                    name={"international"}
                                    value={"CHOICE"}
                                    onChange={handleChange}
                                    checked={values.international === "CHOICE"}
                                    id={"international-CHOICE"}
                                />
                                <InputLabel htmlFor="international-CHOICE">
                                    {t("SUB_UPDATE_PREFERENCES_OTHER_VALUE")}
                                </InputLabel>
                            </FormLine>
                            {
                                values.international === "CHOICE" && (
                                    <SubChoice>
                                        <FormLine className={"mb-3 mt-5"}>
                                            {t("SUB_UPDATE_PREFERENCES_INTERNATIONAL_TITLE")}
                                        </FormLine>
                                        {
                                            Object.keys(this.internationalChoices).map(continent => {
                                                return (
                                                    <div key={continent}>
                                                        <FormLine>
                                                            <InputRadio
                                                                type={"radio"}
                                                                name={"internationalCHOICE"}
                                                                value={continent}
                                                                onChange={handleChange}
                                                                checked={values.internationalCHOICE === continent}
                                                                id={"internationalCHOICE" + continent}
                                                            />
                                                            <InputLabel htmlFor={"internationalCHOICE" + continent}>
                                                                {t("SUB_UPDATE_PREFERENCES_INTERNATIONAL_" + continent)}
                                                            </InputLabel>
                                                        </FormLine>
                                                        {
                                                            values.internationalCHOICE === continent && (
                                                                <SubChoice>
                                                                    {
                                                                        this.internationalChoices[continent].map(choice => {
                                                                            return choice === "OTHER" ? (
                                                                                <div key={choice}>
                                                                                    <FormLine>
                                                                                        <InputCheckbox
                                                                                            type={"checkbox"}
                                                                                            name={"internationalCHOICE" + continent}
                                                                                            value={"OTHER"}
                                                                                            onChange={handleChange}
                                                                                            checked={values["internationalCHOICE" + continent]?.includes(choice)}
                                                                                            id={"internationalCHOICEOTHER"}
                                                                                        />
                                                                                        <InputLabel
                                                                                            htmlFor={"internationalCHOICEOTHER"}>
                                                                                            {t("SUB_UPDATE_PREFERENCES_OTHER")}
                                                                                        </InputLabel>
                                                                                    </FormLine>
                                                                                    {
                                                                                        values["internationalCHOICE" + continent]?.includes(choice) && (
                                                                                            <FormLine>
                                                                                                <Input
                                                                                                    type={"text"}
                                                                                                    name={"internationalCHOICEOTHER" + continent}
                                                                                                    value={values["internationalCHOICEOTHER" + continent]}
                                                                                                    onChange={handleChange}
                                                                                                />
                                                                                            </FormLine>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            ) : (
                                                                                <FormLine key={choice}>
                                                                                    <InputCheckbox
                                                                                        type={"checkbox"}
                                                                                        name={"internationalCHOICE" + continent}
                                                                                        value={choice}
                                                                                        onChange={handleChange}
                                                                                        checked={values["internationalCHOICE" + continent]?.includes(choice)}
                                                                                        id={"internationalCHOICE" + continent + choice}
                                                                                    />
                                                                                    <InputLabel
                                                                                        htmlFor={"internationalCHOICE" + continent + choice}>
                                                                                        {t("SUB_UPDATE_PREFERENCES_INTERNATIONAL_" + continent + "_" + choice)}
                                                                                    </InputLabel>
                                                                                </FormLine>
                                                                            )
                                                                        })
                                                                    }
                                                                </SubChoice>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </SubChoice>
                                )
                            }
                            <FormSepartor/>
                            <FormLine>
                                <Label>{t("SUB_UPDATE_PREFERENCES_LABEL_START") + t("SUB_UPDATE_PREFERENCES_LABEL_NATIONAL")}</Label>
                            </FormLine>
                            <FormLine>
                                <InputRadio
                                    type={"radio"}
                                    name={"national"}
                                    value={"REDAC"}
                                    onChange={handleChange}
                                    checked={values.national === "REDAC"}
                                    id={"national-REDAC"}
                                />
                                <InputLabel htmlFor="national-REDAC">
                                    {t("SUB_UPDATE_PREFERENCES_REDAC_VALUE")}
                                </InputLabel>
                            </FormLine>
                            <FormLine>
                                <InputRadio
                                    type={"radio"}
                                    name={"national"}
                                    value={"CHOICE"}
                                    onChange={handleChange}
                                    checked={values.national === "CHOICE"}
                                    id={"national-CHOICE"}
                                />
                                <InputLabel htmlFor="national-CHOICE">
                                    {t("SUB_UPDATE_PREFERENCES_OTHER_VALUE")}
                                </InputLabel>
                            </FormLine>
                            {
                                values.national === "CHOICE" && (
                                    <SubChoice>
                                        <FormLine className={"mb-3 mt-5"}>
                                            {t("SUB_UPDATE_PREFERENCES_NATIONAL_TITLE")}
                                        </FormLine>
                                        {
                                            this.nationalChoices.map(val => {
                                                return (
                                                    <FormLine key={val}>
                                                        <InputRadio
                                                            type={"radio"}
                                                            name={"nationalCHOICE"}
                                                            value={val}
                                                            onChange={handleChange}
                                                            checked={values.nationalCHOICE === val}
                                                            id={"nationalCHOICE" + val}
                                                        />
                                                        <InputLabel htmlFor={"nationalCHOICE" + val}>
                                                            {t("SUB_UPDATE_PREFERENCES_NATIONAL_" + val)}
                                                        </InputLabel>
                                                    </FormLine>
                                                )
                                            })
                                        }
                                    </SubChoice>
                                )
                            }
                            <FormSepartor/>
                            <FormLine>
                                <Label>{t("SUB_UPDATE_PREFERENCES_LABEL_START") + t("SUB_UPDATE_PREFERENCES_LABEL_LOCAL")}</Label>
                            </FormLine>
                            <FormLine>
                                <InputRadio
                                    type={"radio"}
                                    name={"local"}
                                    value={"REDAC"}
                                    onChange={handleChange}
                                    checked={values.local === "REDAC"}
                                    id={"local-REDAC"}
                                />
                                <InputLabel htmlFor="local-REDAC">
                                    {t("SUB_UPDATE_PREFERENCES_LOCAL_REDAC")}
                                </InputLabel>
                            </FormLine>
                            <FormLine>
                                <InputRadio
                                    type={"radio"}
                                    name={"local"}
                                    value={"CHOICE"}
                                    onChange={handleChange}
                                    checked={values.local === "CHOICE"}
                                    id={"local-CHOICE"}
                                />
                                <InputLabel htmlFor="local-CHOICE">
                                    {t("SUB_UPDATE_PREFERENCES_OTHER_VALUE")}
                                </InputLabel>
                            </FormLine>
                            {
                                values.local === "CHOICE" && (
                                    <SubChoice>
                                        <FormLine className={"mb-3 mt-5"}>
                                            {t("SUB_UPDATE_PREFERENCES_LOCAL_CHOICE")}
                                        </FormLine>
                                        <Select
                                            name={"localCANTON"}
                                            value={values.localCANTON || ''}
                                            onChange={handleChange}
                                        >
                                            {Object.keys(Constants.cantons).map(key => {
                                                return (
                                                    <option
                                                        key={key}
                                                        value={key}
                                                    >
                                                        {Constants.cantons[key]}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    </SubChoice>
                                )
                            }
                            <SubmitWrapper>
                                <Button
                                    onClick={() => this.clickSubmit(isValid)}
                                    type={"submit"}
                                >
                                    {t("PROFILE_UPDATE_PROFILE_SUBMIT")}
                                </Button>
                            </SubmitWrapper>
                        </form>
                    )}
                </Formik>
            </FormWrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        subscriber: state.currentSub !== undefined ? state.profile.subscribers[state.currentSub] : undefined,
    }
};


export default connect(mapStateToProps, null)(withTranslation()(UpdateSubPreferences));
