import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {
    CommentAuthor,
    CommentAuthorMobile,
    CommentBloc, CommentDate,
    CommentGreenLine,
    CommentGreenLineWrapper,
    CommentProfileImage,
    CommentText
} from "../../common/Elements";
import tw from "twin.macro";
import {ReactComponent as Settings} from "feather-icons/dist/icons/more-horizontal.svg";
import MagazineService from "../../../services/magazine.service";
import {toastError, toastSuccess} from "../../common/Toast";

const EditContainer = tw.div`absolute top-0 right-0 sm:right-0.5 sm:top-0.5 text-green-classic inline-block`;
const ButtonMore = tw.button`outline-none focus:outline-none`;
const Dropdown = tw.div`rounded-md bg-white shadow-xs`;
const DropdownItem = tw.a`block px-4 py-2 text-sm leading-5 text-purple-classic hover:bg-green-pastel focus:outline-none focus:bg-green-pastel`;

class Comment extends React.Component {

    constructor(props) {
        super(props);

        this.dateOption = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit'
        };

        this.state = {
            editMenuOpen: false
        };

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && this.wrapperRef.current !== null && !this.wrapperRef.current.contains(event.target)) {
            this.setState({editMenuOpen: false});
        }
    }

    clickMore = () => {
        this.setState({
            editMenuOpen: !this.state.editMenuOpen
        })
    };

    deleteComment = () => {
        MagazineService.deleteComment(this.props.comment.commentId)
            .then(resp => {
                this.props.onDelete();
                toastSuccess(this.props.t("COMMENT_DELETE_SUCCESS"));
            })
            .catch(err => {
                toastError(this.props.t("ERROR_SERVER"));
            });
    }

    render() {
        const com = this.props.comment;
        const {t} = this.props;

        const owner = this.props.profile.customerId === com.authorId;
        const admin = this.props.subscriber.admin;

        const dateStr = new Date(com.creationDate).toLocaleDateString(this.props.language, this.dateOption);
        return (
            <CommentBloc>
                <CommentGreenLineWrapper><CommentGreenLine/></CommentGreenLineWrapper>
                <CommentProfileImage src={com.authorPicture}/>
                <CommentAuthorMobile>{com.author}</CommentAuthorMobile>
                <CommentText>
                    <CommentAuthor>{com.author}<CommentDate>({dateStr})</CommentDate></CommentAuthor>
                    {com.content}
                </CommentText>
                {
                    ((owner || admin) && this.props.magazineOpen) && (
                        <EditContainer ref={this.wrapperRef}>
                            <ButtonMore onClick={this.clickMore}>
                                <Settings style={{height: 20, width: 20, margin: 10}}/>
                            </ButtonMore>
                            {
                                this.state.editMenuOpen ? (
                                    <Dropdown>
                                        <div
                                            className="origin-top-right absolute right-0 mt-2 w-50 rounded-md shadow-lg">
                                            <div className="rounded-md bg-white shadow-xs">
                                                <div className="py-1" role="menu">
                                                    {(owner || admin) &&
                                                    <DropdownItem
                                                        onClick={this.deleteComment}>{t("DELETE_BUTTON")}</DropdownItem>}
                                                </div>
                                            </div>
                                        </div>
                                    </Dropdown>
                                ) : null
                            }

                        </EditContainer>
                    )
                }
            </CommentBloc>
        )
    }


}

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        language: state.profile.language,
        subscriber: state.currentSub !== undefined ? state.profile.subscribers[state.currentSub] : undefined,
    }
};

export default connect(mapStateToProps, null)(withTranslation()(Comment));
