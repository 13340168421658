import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {
    ButtonWithoutMargin,
    FormLine,
    Input,
    InputEmpty,
    InputWrapper,
    Select,
    SignupStepButtonLine,
    SignupStepButtonWrapper,
    SignupStepContent,
    SignupStepHeader,
    SignupStepNumber,
    SignupStepSubTitle,
    SignupStepTitle,
    SignupStepTitleWrapper,
    SignupStepWrapper,
} from "../common/Elements";

import number from "../../images/signup/magiris-inscription_02.png";
import Constants from "../../Constants";
import {CountryDropdown} from "react-country-region-selector";
import {Formik} from "formik";
import * as yup from "yup";
import tw from "twin.macro";

const SubName = tw.div`mt-3 mb-8 text-lg`;

class SignupStep2 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            submitAlreadyClick: false,
            redirect: ""
        };

        this.schema = yup.object({
            firstName: yup.string().min(2).required(),
            lastName: yup.string().min(2).required(),
            address: yup.string().required(),
            zipcode: yup.string().required(),
            city: yup.string().required(),
            country: yup.string().required(),
            canton: yup.string().when("country", {
                is: "CH",
                then: yup.string().required()
            }),
            relationship: yup.string().required(),
            relationShipOther: yup.string().when("relationship", {
                is: "OTHER",
                then: yup.string().required()
            })
        });

        this.schemaInvitation = yup.object({
            relationship: yup.string().required(),
            relationShipOther: yup.string().when("relationship", {
                is: "OTHER",
                then: yup.string().required()
            })
        });
    }

    activeStep = () => {
        this.props.changeStep(2);
    }

    clickSubmit = (isValid) => {
        this.setState({
            submitAlreadyClick: true
        });
    }

    handleSubmit = (fields) => {
        const payload = {...fields};

        this.props.handleSubmit(2, payload);
    }

    render() {
        const {t} = this.props;

        return (
            <SignupStepWrapper className={!this.props.isActive && "opacity-50"}>
                <SignupStepHeader className={"cursor-pointer"} onClick={this.activeStep}>
                    <SignupStepNumber src={number}/>
                    <SignupStepTitleWrapper>
                        <SignupStepSubTitle>{t("SIGNUP_STEP2_TITLE")}</SignupStepSubTitle>
                        <SignupStepTitle>{t("SIGNUP_STEP2_SUBTITLE")}</SignupStepTitle>
                    </SignupStepTitleWrapper>
                </SignupStepHeader>
                <SignupStepContent>

                    <Formik
                        validationSchema={this.props.isInvitation ? this.schemaInvitation : this.schema}
                        onSubmit={this.handleSubmit}
                        initialValues={{
                            civility: "MR",
                        }}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              values,
                              touched,
                              isValid,
                              errors,
                              isSubmitting,
                              setValues
                          }) => (
                            <form onSubmit={handleSubmit}>
                                {
                                    !this.props.isInvitation ? (
                                        <div>
                                            <FormLine>
                                                <Select
                                                    name={"civility"}
                                                    value={values.civility || ''}
                                                    onChange={handleChange}
                                                    disabled={!this.props.isActive}
                                                >
                                                    <option value="MR">{t("SIGNUP_CIVILITY_MR_PLACEHOLDER")}</option>
                                                    <option value="MS">{t("SIGNUP_CIVILITY_MS_PLACEHOLDER")}</option>
                                                    <option
                                                        value="BOTH">{t("SIGNUP_CIVILITY_BOTH_PLACEHOLDER")}</option>
                                                </Select>
                                            </FormLine>
                                            <FormLine>
                                                <Input
                                                    placeholder={values.civility === "BOTH" ? t("SIGNUP_S2_FIRSTNAMES_PLACEHOLDER") : t("SIGNUP_S2_FIRSTNAME_PLACEHOLDER")}
                                                    name={"firstName"}
                                                    value={values.firstName || ''}
                                                    onChange={handleChange}
                                                    isInvalid={this.state.submitAlreadyClick && errors.firstName}
                                                    disabled={!this.props.isActive}
                                                />
                                            </FormLine>
                                            <FormLine>
                                                <Input
                                                    placeholder={values.civility === "BOTH" ? t("SIGNUP_S2_LASTNAMES_PLACEHOLDER") : t("SIGNUP_S2_LASTNAME_PLACEHOLDER")}
                                                    name={"lastName"}
                                                    value={values.lastName || ''}
                                                    onChange={handleChange}
                                                    isInvalid={this.state.submitAlreadyClick && errors.lastName}
                                                    disabled={!this.props.isActive}
                                                />
                                            </FormLine>
                                            <div className={"h-8"}/>
                                            <FormLine>
                                                <Input
                                                    placeholder={values.civility === "BOTH" ? t("SIGNUP_S2_ADDRESSS_PLACEHOLDER") : t("SIGNUP_S2_ADDRESS_PLACEHOLDER")}
                                                    name={"address"}
                                                    value={values.address || ''}
                                                    onChange={handleChange}
                                                    isInvalid={this.state.submitAlreadyClick && errors.address}
                                                    disabled={!this.props.isActive}
                                                />
                                            </FormLine>
                                            <FormLine>
                                                <Input
                                                    placeholder={t("SIGNUP_S2_ZIPCODE_PLACEHOLDER")}
                                                    name={"zipcode"}
                                                    value={values.zipcode || ''}
                                                    onChange={handleChange}
                                                    isInvalid={this.state.submitAlreadyClick && errors.zipcode}
                                                    disabled={!this.props.isActive}
                                                />
                                            </FormLine>
                                            <FormLine>
                                                <Input
                                                    placeholder={t("SIGNUP_S2_CITY_PLACEHOLDER")}
                                                    name={"city"}
                                                    value={values.city || ''}
                                                    onChange={handleChange}
                                                    isInvalid={this.state.submitAlreadyClick && errors.city}
                                                    disabled={!this.props.isActive}
                                                />
                                            </FormLine>
                                            <FormLine>
                                                <InputWrapper
                                                    isInvalid={this.state.submitAlreadyClick && errors.country}>
                                                    <CountryDropdown
                                                        className={"bg-white appearance-none"}
                                                        style={{width: "100%", outline: "none"}}
                                                        value={values.country || ''}
                                                        name={"country"}
                                                        valueType={"short"}
                                                        onChange={(val, e) => {
                                                            handleChange(e);
                                                        }}
                                                        whitelist={Constants.countriesAvailableUpper}
                                                        priorityOptions={Constants.defaultCountriesUpper}
                                                        defaultOptionLabel={t("SIGNUP_S2_COUNTRY_PLACEHOLDER")}
                                                        disabled={!this.props.isActive}
                                                    />
                                                </InputWrapper>
                                            </FormLine>
                                            <FormLine>
                                                {
                                                    values.country === "CH" ? (
                                                        <Select
                                                            isInvalid={this.state.submitAlreadyClick && errors.canton}
                                                            name={"canton"}
                                                            value={values.canton || 'default'}
                                                            onChange={handleChange}
                                                            disabled={!this.props.isActive}
                                                        >
                                                            <option
                                                                value="default"
                                                                disabled
                                                            >
                                                                {t("SIGNUP_S2_CANTON_PLACEHOLDER")}
                                                            </option>
                                                            {Object.keys(Constants.cantons).map(key => {
                                                                return (
                                                                    <option
                                                                        key={key}
                                                                        value={key}
                                                                    >
                                                                        {Constants.cantons[key]}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Select>
                                                    ) : (
                                                        <InputEmpty/>
                                                    )
                                                }
                                            </FormLine>
                                        </div>
                                    ) : (
                                        <SubName>
                                            {this.props.subName}
                                        </SubName>
                                    )
                                }
                                <FormLine>
                                    <Select
                                        isInvalid={this.state.submitAlreadyClick && errors.relationship}
                                        name={"relationship"}
                                        value={values.relationship || 'default'}
                                        onChange={handleChange}
                                        disabled={!this.props.isActive}
                                    >
                                        <option
                                            value="default"
                                            disabled
                                        >
                                            {t("SIGNUP_RELATIONSHIP_PLACEHOLDER").replace("%NAME%", values.firstName || t("SIGNUP_SUBSCRIBER_LABEL"))}
                                        </option>
                                        {Constants.relationShips.map(key => {
                                            return (
                                                <option
                                                    key={key}
                                                    value={key}
                                                >
                                                    {t("SIGNUP_RELATIONSHIP_" + key + "_LABEL")}
                                                </option>
                                            );
                                        })}
                                    </Select>
                                </FormLine>
                                <FormLine>
                                    {
                                        values.relationship === "OTHER" ? (
                                            <Input
                                                placeholder={t("SIGNUP_RELATIONSHIP_PLACEHOLDER").replace("%NAME%", values.firstName || t("SIGNUP_SUBSCRIBER_LABEL"))}
                                                name={"relationShipOther"}
                                                value={values.relationShipOther || ''}
                                                onChange={handleChange}
                                                isInvalid={this.state.submitAlreadyClick && errors.relationShipOther}
                                                disabled={!this.props.isActive}
                                            />
                                        ) : <InputEmpty/>
                                    }
                                </FormLine>
                                {this.props.isActive && <SignupStepButtonWrapper>
                                    <SignupStepButtonLine/>
                                    <ButtonWithoutMargin
                                        type={"submit"}
                                        onClick={() => this.clickSubmit(isValid)}
                                    >
                                        {t("SIGNUP_SUBMIT_BUTTON")}
                                    </ButtonWithoutMargin>
                                    <SignupStepButtonLine/>
                                </SignupStepButtonWrapper>}
                            </form>
                        )}
                    </Formik>
                </SignupStepContent>
            </SignupStepWrapper>
        )

    }

}

export default connect(null, null)(withTranslation()(SignupStep2));
