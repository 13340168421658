import rootReducer from './reducer'
import storage from 'redux-persist/lib/storage'
import {createStore} from "redux";
import {persistStore, persistReducer} from 'redux-persist'

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

// for dev export let store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
export let store = createStore(persistedReducer);
export let persistor = persistStore(store);
