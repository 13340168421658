import React from "react";
import SubscriptionService from "../../services/subscription.service";
import Loader from "../common/Loader";
import {
    Logo,
    SignupFormulaCurrency,
    SignupFormulaIcon,
    SignupFormulaPrice,
    SignupFormulaSub, SignupFormulaWrapper,
    Title
} from "../common/Elements";
import logo from "../../images/iris_logo_violet.png";
import tw from "twin.macro";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {priceFormulaFormat} from "../../services/format";
import {toastError} from "../common/Toast";
import formula1 from "../../images/signup/magiris-formule_x1.png";
import formula2 from "../../images/signup/magiris-formule_x6.png";
import formula3 from "../../images/signup/magiris-formule_x12.png";

const PricingSelect = tw.div`p-5`;
const PricingList = tw.div`flex justify-center flex-col lg:flex-row items-center lg:items-stretch relative space-x-3`;

class SelectFormula extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currency: this.props.currency,
            loading: true,
            formulas: []
        }

        this.formulaStyle = [
            {
                image: formula1,
                className: "border-green-pastel hover:bg-green-pastel",
                classSubName: "bg-green-pastel"
            },
            {
                image: formula2,
                className: "border-green-classic hover:bg-green-pastel",
                classSubName: "bg-green-pastel"
            },
            {
                image: formula3,
                className: "border-purple-pastel bg-purple-pastel hover:bg-purple-classic text-white",
                classSubName: "bg-white text-purple-pastel"
            }
        ]
    }

    componentDidMount() {
        //TODO select by country
        SubscriptionService.getFormulas("CH", this.state.currency)
            .then(resp => {
                this.setState({
                    loading: false,
                    formulas: resp.data
                })
            })
            .catch(err => {
                toastError(this.props.t("ERROR_SERVER"));
            });
    }


    render() {
        if (this.state.loading) {
            return <Loader/>
        }

        const {t} = this.props;


        return (
            <PricingSelect>
                {
                    this.state.loading ? (
                        <Loader/>
                    ) : null
                }
                <Logo src={logo} alt={"Logo"}/>
                <Title>{t("PAYMENT_SELECT_FORMULA")}</Title>
                <PricingList>
                    {
                        this.state.formulas.map((formula, idx) => {
                            return (
                                <SignupFormulaWrapper
                                    key={formula.formulaId}
                                    className={this.formulaStyle[idx].className}
                                    onClick={() => this.props.onSelect(formula)}
                                >
                                    <SignupFormulaIcon src={this.formulaStyle[idx].image}/>
                                    <SignupFormulaPrice>{priceFormulaFormat(this.state.currency, formula.amountWithVAT)}</SignupFormulaPrice>
                                    <SignupFormulaCurrency>{this.state.currency}</SignupFormulaCurrency>
                                    <SignupFormulaSub
                                        className={this.formulaStyle[idx].classSubName}>{t(formula.details)}</SignupFormulaSub>
                                </SignupFormulaWrapper>
                            )
                        })
                    }
                </PricingList>
            </PricingSelect>
        )
    }
}

export default connect(null, null)(withTranslation()(SelectFormula));
