import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {
    ButtonWithoutMargin,
    FormLine,
    Input, LinkStyle,
    Select,
    SignupStepButtonLine,
    SignupStepButtonWrapper,
    SignupStepContent,
    SignupStepHeader,
    SignupStepNumber,
    SignupStepSubTitle,
    SignupStepTitle,
    SignupStepTitleWrapper,
    SignupStepWrapper,
} from "../common/Elements";

import number from "../../images/signup/magiris-inscription_01.png";
import {Formik} from "formik";
import * as yup from "yup";
import UserService from "../../services/user.service";
import {getLanguage} from "../../services/languages";
import {Redirect} from "react-router-dom";
import tw from "twin.macro";

const TextWrapper = tw.div`py-3 text-center`;
const Separtor = tw.div`border-t-2 w-16 mx-auto mb-5`;

class SignupStep1 extends React.Component {

    constructor(props) {
        super(props);

        let initialValues = {
            civility: "MR"
        };
        let isAuthenticated = false;

        if (UserService.isAuthenticated()) {
            initialValues = this.props.profile;
            isAuthenticated = true;
        }

        this.state = {
            isAuthenticated: isAuthenticated,
            initialValues: initialValues,
            submitAlreadyClick: false,
            loading: true,
        };

        const {t} = this.props;

        this.schema = yup.object({
            firstName: yup.string().min(2).required(),
            lastName: yup.string().min(2).required(),
            email: yup.string().email().required(),
            password: yup.string().required(" ") // /!\ Do not remove space
                .min(8, t("SIGNUP_PASSWORD_SIZE_ERROR"))
                .max(30, t("SIGNUP_PASSWORD_SIZE_ERROR"))
                .matches(/[a-z]/, t("SIGNUP_PASSWORD_LOWER_ERROR"))
                .matches(/[A-Z]/, t("SIGNUP_PASSWORD_UPPER_ERROR"))
                .matches(/[0-9]/, t("SIGNUP_PASSWORD_NUMBER_ERROR")),
        });
    }

    activeStep = () => {
        if (!this.state.isAuthenticated) {
            this.props.changeStep(1);
        }
    }

    clickSubmit = (isValid) => {
        this.setState({
            submitAlreadyClick: true
        });
    }

    handleSubmit = (fields) => {
        const payload = {...fields};

        payload["language"] = getLanguage().toUpperCase();
        if (!["FR", "DE", "IT"].includes(payload["language"])) {
            payload["language"] = "FR";
        }

        this.props.handleSubmit(1, payload);
    }

    render() {
        const {t} = this.props;

        if (this.state.redirect !== undefined) {
            if (this.state.redirect === "/login") {
                return <Redirect
                    to={{
                        pathname: "/login",
                        state: {from: {pathname: "/signup/" + this.props.subToken}}
                    }}
                />
            }
            return <Redirect to={this.state.redirect}/>
        }

        return (
            <SignupStepWrapper className={!this.props.isActive && "opacity-50"}>
                <SignupStepHeader className={!this.state.isAuthenticated ? "cursor-pointer" : ""}
                                  onClick={this.activeStep}>
                    <SignupStepNumber src={number}/>
                    <SignupStepTitleWrapper>
                        <SignupStepSubTitle>{t("SIGNUP_STEP1_TITLE")}</SignupStepSubTitle>
                        <SignupStepTitle>{t("SIGNUP_STEP1_SUBTITLE")}</SignupStepTitle>
                    </SignupStepTitleWrapper>
                </SignupStepHeader>
                <SignupStepContent>
                    {
                        this.props.isInvitation && !this.state.isAuthenticated && <div>
                            <TextWrapper>
                                {t("SIGNUP_QUESTION_ACCOUNT")}
                                <a onClick={() => this.setState({redirect: "/login"})}>
                                    <LinkStyle>{" " + t("SIGNUP_LOGIN")}</LinkStyle>
                                </a>
                            </TextWrapper>
                            <Separtor/>

                        </div>
                    }
                    <Formik
                        validationSchema={this.schema}
                        onSubmit={this.handleSubmit}
                        initialValues={this.state.initialValues}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              values,
                              touched,
                              isValid,
                              errors,
                              isSubmitting,
                              setValues
                          }) => (
                            <form onSubmit={handleSubmit}>
                                <FormLine>
                                    <Select
                                        name={"civility"}
                                        value={values.civility || ''}
                                        onChange={handleChange}
                                        disabled={this.state.isAuthenticated || !this.props.isActive}
                                    >
                                        <option value="MR">{t("SIGNUP_CIVILITY_MR_PLACEHOLDER")}</option>
                                        <option value="MS">{t("SIGNUP_CIVILITY_MS_PLACEHOLDER")}</option>
                                        <option value="BOTH">{t("SIGNUP_CIVILITY_BOTH_PLACEHOLDER")}</option>
                                    </Select>
                                </FormLine>
                                <FormLine>
                                    <Input
                                        placeholder={t("SIGNUP_FIRSTNAME_PLACEHOLDER")}
                                        name={"firstName"}
                                        value={values.firstName || ''}
                                        onChange={handleChange}
                                        isInvalid={this.state.submitAlreadyClick && errors.firstName}
                                        disabled={this.state.isAuthenticated || !this.props.isActive}
                                    />
                                </FormLine>
                                <FormLine>
                                    <Input
                                        placeholder={t("SIGNUP_LASTNAME_PLACEHOLDER")}
                                        name={"lastName"}
                                        value={values.lastName || ''}
                                        onChange={handleChange}
                                        isInvalid={this.state.submitAlreadyClick && errors.lastName}
                                        disabled={this.state.isAuthenticated || !this.props.isActive}
                                    />
                                </FormLine>
                                <FormLine>
                                    <Input
                                        placeholder={t("SIGNUP_EMAIL_PLACEHOLDER")}
                                        name={"email"}
                                        type={"email"}
                                        value={values.email || ''}
                                        onChange={handleChange}
                                        isInvalid={this.state.submitAlreadyClick && errors.email}
                                        disabled={this.state.isAuthenticated || !this.props.isActive}
                                    />
                                </FormLine>
                                <FormLine className="text-red-500 ml-1 mb-1">
                                    {
                                        errors.password ? errors.password : errors.passwordConfirm
                                    }
                                </FormLine>
                                <FormLine>
                                    {!this.state.isAuthenticated && <Input
                                        placeholder={t("SIGNUP_PASSWORD_PLACEHOLDER")}
                                        name={"password"}
                                        type={"password"}
                                        value={values.password || ''}
                                        onChange={handleChange}
                                        isInvalid={this.state.submitAlreadyClick && errors.password}
                                        disabled={!this.props.isActive}
                                    />}
                                </FormLine>
                                {this.props.isActive && <SignupStepButtonWrapper>
                                    <SignupStepButtonLine/>
                                    <ButtonWithoutMargin
                                        onClick={() => this.clickSubmit(isValid)}
                                        type={"submit"}
                                    >
                                        {t("SIGNUP_SUBMIT_BUTTON")}
                                    </ButtonWithoutMargin>
                                    <SignupStepButtonLine/>
                                </SignupStepButtonWrapper>}
                            </form>
                        )}
                    </Formik>
                </SignupStepContent>
            </SignupStepWrapper>
        )

    }

}

const mapStateToProps = (state) => {
    return {
        jwt: state.jwt,
        profile: state.profile,
    }
};

export default connect(mapStateToProps, null)(withTranslation()(SignupStep1));
