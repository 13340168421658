import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import addPictureIcon from "../../../images/add_picture.png";
import tw from "twin.macro";
import ImageUploader from "react-images-upload";
import MagazineService from "../../../services/magazine.service";
import Loader from "../../common/Loader";
import {ButtonPurple} from "../../common/Elements";
import {toastSuccess} from "../../common/Toast";
import {autoRotateFile} from "../../../services/tools";

const Container = tw.div`relative p-5 px-6`;
const PublicationTextInput = tw.textarea`w-full placeholder-purple-classic text-purple-classic italic outline-none resize-none h-28 border-b-1 border-green-pastel`;
const AddPublicationButtons = tw.div`flex-col h-12 pb-2 pt-2 inline-block w-full`;
const PictureButton = tw.div`block sm:inline-block text-center sm:text-left h-12 leading-10 text-purple-pastel text-sm font-medium ml-0 xl:ml-5 w-auto mb-5 mt-2 sm:my-0`;
const PictureIcon = tw.img`inline-block h-10`;
const PublishButton = tw.div`sm:float-right text-center`;
const Error = tw.div`text-center font-medium text-red-500 pt-2 pb-3`;
const LoaderWrapper = tw.div`absolute rounded-lg bg-white bg-opacity-75 pt-12 left-0 top-0 z-20 text-center w-full h-full`;

class PublicationForm extends React.Component {

    constructor(props) {
        super(props);

        this.initialState = {
            error: "",
            text: "",
            pictureInputToggle: false,
            picture: "",
            pictureUploading: false,
            loading: false
        };

        this.state = this.initialState;

        this.onDrop = this.onDrop.bind(this);
    }

    textChange = (e) => {
        this.setState({
            text: e.target.value,
            error: ""
        });
    }

    toggleUpload = () => {
        this.setState({
            pictureInputToggle: true,
        });
    }

    onDrop(pictureFile, pictureDataURLs) {
        if (pictureFile.length === 0) {
            //Delete image
            this.setState({
                picture: "",
                pictureUploading: false
            });
            return;
        }


        this.setState({
            pictureUploading: true,
        });

        autoRotateFile(pictureFile[0])
            .then(file => this.uploadPublicationFile(file))
            .catch(err => this.uploadPublicationFile(pictureFile[0]));
    }

    uploadPublicationFile = (file) => {
        const {t, sub} = this.props;

        MagazineService.uploadPublicationImage(sub.subscriberId, file)
            .then((response) => {
                this.setState({
                    error: "",
                    picture: response.data,
                    pictureUploading: false
                }, () => {
                    if (this.state.loading) {
                        this.publish()
                    }
                });
            })
            .catch((err) => {
                this.setState({
                    error: t("HOME_PUBLICATION_ERROR"),
                    pictureUploading: false
                });
            });
    }

    publish = () => {
        const {t, sub} = this.props;

        if (this.state.text === "") {
            this.setState({
                error: t("HOME_PUBLICATION_NO_TEXT")
            });
            return;
        }

        if (this.state.picture === "" && !this.state.pictureUploading) {
            this.setState({
                error: t("HOME_PUBLICATION_NO_PICTURE")
            });
            return;
        }

        if (this.state.pictureUploading) {
            this.setState({
                loading: true
            });
            return;
        }

        this.setState({
            loading: true
        }, () => {
            MagazineService.addPublication(sub.subscriberId, this.state.text, this.state.picture)
                .then((response) => {
                    this.setState({
                        loading: false,
                        error: ""
                    });
                    this.resetForm();
                    this.props.newPublicationCallback();
                    toastSuccess(t("HOME_PUBLICATION_SUCCESS"));
                })
                .catch((err) => {
                    this.setState({
                        loading: false,
                        error: t("HOME_PUBLICATION_ERROR")
                    });
                });
        });

    }

    resetForm = () => {
        this.setState(this.initialState);
    }

    render() {

        const {t, sub} = this.props;

        return (
            <Container>
                {this.state.error !== "" && <Error>{this.state.error}</Error>}
                <PublicationTextInput
                    maxLength={"120"}
                    placeholder={
                        t("HOME_PUBLICATION_PLACEHOLDER") +
                        " " +
                        sub.firstName +
                        "..."}
                    onChange={this.textChange}
                    value={this.state.text}
                />
                {
                    this.state.pictureInputToggle ? (
                        <ImageUploader
                            withIcon={true}
                            buttonText={t("HOME_PICTURE_CHOOSE")}
                            label={t("HOME_PICTURE_LABEL")}
                            fileSizeError={t("HOME_PICTURE_SIZE_ERROR")}
                            fileTypeError={t("HOME_PICTURE_TYPE_ERROR")}
                            singleImage={true}
                            onChange={this.onDrop}
                            imgExtension={[".jpg", ".png", ".jpeg"]}
                            maxFileSize={5242880}
                            withPreview={true}
                            buttonStyles={{
                                backgroundColor: "#4d4dff"
                            }}
                        />
                    ) : null
                }
                <AddPublicationButtons>
                    {
                        !this.state.pictureInputToggle ? (
                            <PictureButton onClick={this.toggleUpload}>
                                <PictureIcon src={addPictureIcon} alt={"Add picture ico"}/>
                                {t("HOME_ADD_PICTURE")}
                            </PictureButton>
                        ) : null
                    }
                    <PublishButton onClick={() => this.publish(true)}>
                        <ButtonPurple>{t("HOME_PUBLISH")}</ButtonPurple>
                    </PublishButton>
                </AddPublicationButtons>
                {this.state.loading && <LoaderWrapper><Loader/></LoaderWrapper>}
            </Container>
        )
    }
}

export default connect(null, null)(withTranslation()(PublicationForm));
