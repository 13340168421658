import Constants from "../Constants";
import {store} from "../redux/store";

const axios = require('axios');

class PaymentService {

    getCodePromoInfo(code) {
        return axios
            .get(Constants.baseApiUrl + Constants.getPromoCode + code);
    }

    createTransaction(payload) {
        return axios
            .put(
                Constants.baseApiUrl + Constants.createTransaction,
                payload,
                this.getHeaders()
            )
    }

    getHeaders() {
        const token = store.getState().jwt;
        return {
            headers: {Authorization: `Bearer ${token}`}
        };
    }
}

export default new PaymentService();
