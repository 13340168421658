import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {
    CommentAdd,
    CommentBloc,
    CommentGreenLine,
    CommentGreenLineWrapper,
    CommentInput,
    CommentProfileImage
} from "../../common/Elements";
import {ReactComponent as SendIcon} from "feather-icons/dist/icons/send.svg";
import MagazineService from "../../../services/magazine.service";

class CommentForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            commentText: ""
        }
    }

    onCommentChange = (e) => {
        this.setState({
            commentText: e.target.value
        })
    }

    sendComment = () => {
        //TODO loader or block 2nd request
        if (this.state.commentText !== "") {
            const pub = this.props.publication;
            const comment = this.state.commentText;
            MagazineService.commentPublication(pub.publicationId, comment)
                .then(response => {
                    this.setState({
                        loading: false,
                        commentText: ""
                    }, () => {
                        this.props.newCommentCallback(comment, response.data);
                    })
                })
                .catch(err => {
                    this.setState({
                        loading: false
                    })
                });
        }
    }

    render() {
        const {t} = this.props;

        return (
            <CommentBloc>
                <CommentGreenLineWrapper><CommentGreenLine/></CommentGreenLineWrapper>
                <CommentProfileImage src={this.props.profile.picture}/>
                <CommentInput
                    placeholder={t("COMMENT_PLACEHOLDER")}
                    onChange={this.onCommentChange}
                    value={this.state.commentText}
                />
                {
                    this.state.commentText.length > 0 ? (
                        <CommentAdd
                            onClick={this.sendComment}
                        >
                            <SendIcon tw="w-6 h-6"/>
                        </CommentAdd>
                    ) : null
                }
            </CommentBloc>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.profile
    }
};

export default connect(mapStateToProps, null)(withTranslation()(CommentForm));
