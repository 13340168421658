import i18n from "i18next";
import detector from "i18next-browser-languagedetector";

import translationsFR from "./translations/fr.json";

i18n
    .use(detector)
    .init({
        resources: {
            fr: {
                translation: translationsFR
            },
        },
        fallbackLng: "fr",

        interpolation: {
            escapeValue: false
        }
    });


export default i18n;
