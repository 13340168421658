import {DISCONNECT, LOGIN, SET_CURRENT_SUB, SET_JWT, SET_PROFILE, SET_SIGNUP_PROCESS} from "./actions";

const initialState = {
    jwt: undefined,
    signupProcess: false,
    profile: {},
    currentSub: undefined,
};

function rootReducer(state = initialState, action) {
    let sub = undefined;

    switch (action.type) {

        case LOGIN:
            sub = undefined;
            if (state.currentSub === undefined) {
                if (action.profile.subscribers.length > 0) {
                    sub = 0;
                }
            } else {
                sub = state.currentSub;
            }

            return {
                ...initialState,
                profile: action.profile,
                jwt: action.jwt,
                currentSub: sub
            };
        case SET_PROFILE:
            sub = undefined;
            if (state.currentSub === undefined || state.profile.subscribers[state.currentSub] === undefined) {
                if (action.profile.subscribers.length > 0) {
                    sub = 0;
                }
            } else {
                sub = state.currentSub;
            }
            if (JSON.stringify(action.profile) === JSON.stringify(state.profile) && state.currentSub === sub) {
                return state
            }
            return {
                ...state,
                profile: action.profile,
                currentSub: sub
            };
        case SET_JWT:
            return {
                ...state,
                jwt: action.jwt
            };
        case SET_SIGNUP_PROCESS:
            return {
                ...state,
                signupProcess: action.bool
            };
        case SET_CURRENT_SUB:
            let subscriberIdx = state.profile.subscribers.findIndex(sub => sub.subscriberId === action.subId);
            if (subscriberIdx === -1) {
                return {
                    ...state
                }
            }
            return {
                ...state,
                currentSub: subscriberIdx
            };
        case DISCONNECT:
            return {
                ...initialState
            };
        default:
            return state;
    }
}

export default rootReducer;
