import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import {ButtonPurple, FormLine, InputWrapper} from "../common/Elements";
import React, {useState} from "react";
import {withTranslation} from "react-i18next";

const StripeForm = ({t, customer, setLoading, paymentSuccessful, paymentError, paymentIntentFnc}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [cardValid, setCardValid] = useState(true);
    const [expValid, setExpValid] = useState(true);
    const [cvcValid, setCvcValid] = useState(true);
    const [clientSecret, setClientSecret] = useState(undefined);

    const handleSubmit = async event => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        setLoading(true);

        try {
            setCardValid(true);
            setCvcValid(true);
            setExpValid(true);

            let localSecret = ""
            if (clientSecret === undefined) {
                localSecret = await createPaymentIntent();
            } else {
                localSecret = clientSecret;
            }

            const payload = await stripe.confirmCardPayment(localSecret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: customer?.firstName + " " + customer?.lastName,
                        email: customer?.email,
                        phone: customer?.phone1,
                        address: {
                            city: customer?.city,
                            country: customer?.country,
                            line1: customer?.address,
                            postal_code: customer?.zipCode,
                        }
                    },
                }
            });

            if (payload.paymentIntent) {
                if (payload.paymentIntent.status === "succeeded") {
                    paymentSuccessful();
                } else {
                    paymentError();
                }
            } else {
                if (payload.error) {
                    switch (payload.error.code) {
                        case 'incomplete_number':
                            setCardValid(false);
                            break;
                        case 'incomplete_expiry':
                            setExpValid(false);
                            break;
                        case 'incomplete_cvc':
                            setCvcValid(false);
                            break;
                        default:
                            paymentError();
                            break;
                    }
                    setLoading(false);
                } else {
                    paymentError();
                }
            }
        } catch (e) {
            setLoading(false);
            paymentError()
        }
    };

    const createPaymentIntent = async () => {
        try {
            const response = await paymentIntentFnc();

            const secret = response.data.pspPayload;
            setClientSecret(secret);
            return secret;
        } catch (e) {
            paymentError()
        }

    }


    return (
        <form onSubmit={handleSubmit}>
            <FormLine>
                <InputWrapper isInvalid={!cardValid}>
                    <CardNumberElement
                        options={{
                            style: stripeStyle,
                            placeholder: t("PAYMENT_CARD_PLACEHOLDER")
                        }}
                    />
                </InputWrapper>
            </FormLine>
            <FormLine>
                <InputWrapper isInvalid={!expValid}>
                    <CardExpiryElement
                        options={{
                            style: stripeStyle,
                            placeholder: t("PAYMENT_EXPIRY_PLACEHOLDER")
                        }}
                    />
                </InputWrapper>
                <InputWrapper isInvalid={!cvcValid}>
                    <CardCvcElement
                        options={{
                            style: stripeStyle,
                            placeholder: t("PAYMENT_CVC_PLACEHOLDER")
                        }}
                    />
                </InputWrapper>
            </FormLine>
            <ButtonPurple
                className={"float-right mt-2"}
                type={"submit"}
            >
                {t("PAYMENT_PAY_LABEL")}
            </ButtonPurple>

        </form>
    );
};

const stripeStyle = {
    base: {
        fontWeight: "light",
        fontSize: '18px',
        color: '#495057',
        '::placeholder': {
            color: '#aab7c4',
        },
        backgroundColor: '#fff',
    },
    invalid: {
        color: '#ff0d0d',
    },
}

export default withTranslation()(StripeForm);
