import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Page, TwoGreenPoint} from "../common/Elements";
import tw from "twin.macro";
import SelectFormula from "./SelectFormula";
import PaymentForm from "./PaymentForm";
import Tracking from "../common/Tracking";

const Container = tw.div`p-5 sm:pt-10v sm:pb-10v`;
const Content = tw.div`relative sm:w-9/12 sm:mx-auto rounded-lg bg-white`;
const RightGreenPointWrapper = tw.div`absolute right--10 hidden sm:block`;
const LeftGreenPointWrapper = tw.div`absolute left--10 mt-96 hidden sm:block`;

class Payment extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currency: "CHF",
            selectedFormula: props.location.state?.formula
        }

    }

    selectFormula = (formula) => {
        this.setState({
            selectedFormula: formula
        })
    }

    deselect = () => {
        this.setState({
            selectedFormula: undefined
        })
    }

    render() {
        return (
            <Page>
                <Tracking/>
                <Container>
                    <Content>
                        <RightGreenPointWrapper>
                            <TwoGreenPoint/>
                        </RightGreenPointWrapper>
                        <LeftGreenPointWrapper>
                            <TwoGreenPoint/>
                        </LeftGreenPointWrapper>
                        {
                            this.state.selectedFormula === undefined ? (
                                <SelectFormula
                                    currency={this.state.currency}
                                    onSelect={this.selectFormula}
                                />
                            ) : (
                                <PaymentForm
                                    goBack={this.deselect}
                                    currency={this.state.currency}
                                    formula={this.state.selectedFormula}
                                    subToken={this.props.match.params.token}
                                    showClosureSelect={this.props.match.params.showClosureSelect}
                                />
                            )
                        }
                    </Content>
                </Container>
            </Page>
        )
    }
}


export default connect(null, null)(withTranslation()(Payment));
