import tw from "twin.macro";
import React from "react";
import Point from "../../images/point.png";
import styled from "styled-components";

export const Page = tw.div`bg-green-pastel bg-opacity-50 min-h-screen pb-20`;

export const Container = tw.div`mx-5 mt-8 lg:w-8/12 lg:mx-auto relative`;

const GreenPoint = tw.img`h-10 my-4`;
const GreenPointRightBox = tw.div`fixed absolute right--10 mt-10 hidden lg:block`;

export const ContainerWithDecorator = ({children}) => {

    return (
        <Container>
            <GreenPointRightBox>
                <GreenPoint src={Point}/>
                <GreenPoint src={Point}/>
            </GreenPointRightBox>
            {children}
        </Container>
    )
}

const TwoGreenPointContainer = tw.div`w-10 mx-auto`;

export const TwoGreenPoint = () => {
    return (
        <TwoGreenPointContainer>
            <GreenPoint src={Point}/>
            <GreenPoint src={Point}/>
        </TwoGreenPointContainer>
    )
}

export const Input = styled.input`
  ${props => props.isInvalid ? tw`border-2 border-red-500` : tw`border border-gray-300`}
  ${tw`outline-none appearance-none rounded-full w-full py-2 px-3 text-purple-classic leading-tight mb-3`}
`;

export const InputWrapper = styled.div`
  ${props => props.isInvalid ? tw`border-2 border-red-500` : tw`border border-gray-300`}
  ${tw`outline-none appearance-none rounded-full w-full py-2 px-3 text-purple-classic leading-tight mb-3`}
`;

export const InputEmpty = styled.div`
  ${tw`w-full py-2 px-3 text-purple-classic leading-tight mb-3`}
`;

export const InputRadio = tw.input`outline-none py-2 px-3 mt-2 text-purple-classic leading-tight mb-3`;
export const InputCheckbox = tw.input`outline-none py-2 px-3 mt-2 text-purple-classic leading-tight mb-3`;
export const InputLabel = styled.label`
   ${props => props.isInvalid ? tw`text-red-500` : tw``}
`;

export const Title = tw.h1`text-center font-semibold text-2xl text-purple-pastel pt-5 pb-2`;
export const TitleBis = tw.h1`text-center text-2xl text-purple-classic pt-5 pb-2`;
export const Button = styled.button`
    ${props => props.disabled ? tw`cursor-not-allowed` : tw`hover:bg-purple-pastel`}
    ${tw`outline-none focus:outline-none bg-purple-classic transition duration-300 text-white h-10 px-6 mt-5 leading-10 lowercase`}
`;

export const ButtonWithoutMargin = styled.button`
    ${props => props.disabled ? tw`cursor-not-allowed` : tw`hover:bg-purple-pastel`}
    ${tw`outline-none focus:outline-none bg-purple-classic transition duration-300 text-white h-10 px-6 leading-10 lowercase`}
`;

export const ButtonFull = styled.div`
    ${props => props.disabled ? tw`cursor-not-allowed` : tw`hover:bg-purple-pastel`}
    ${tw`outline-none focus:outline-none bg-purple-classic transition duration-300 text-white px-6 mt-5 leading-4 lowercase flex flex-col items-center py-3`}
`;


export const Select = styled.select`
  ${props => props.isInvalid ? tw`border-2 border-red-500` : tw`border border-gray-300`}
  ${tw`outline-none appearance-none rounded-full bg-white w-full py-2 px-3 text-purple-classic leading-tight mb-3`}
`;

export const LinkStyle = tw.span`text-purple-classic hover:text-purple-pastel`;

export const CommentGreenLineWrapper = tw.div`absolute top--1 sm:top--2.5 h-10 w-12 sm:w-16 sm:h-16 sm:w-16 ml--12 mb-8 sm:ml--16 pl-015r z-0`;
export const CommentGreenLine = tw.div`rounded-bl-custom-sm sm:rounded-bl-custom-lg border-b-1 border-l-1 border-green-pastel h-full w-full absolute`;
export const CommentBloc = tw.div`mt-5 sm:flex relative w-full`;
export const CommentProfileImage = tw.img`rounded-full w-12 h-12 z-30 inline-block`;
export const CommentAuthor = tw.span`hidden sm:block text-purple-classic font-medium`;
export const CommentAuthorMobile = tw.span`sm:hidden ml-2 text-purple-classic font-medium`;
export const CommentDate = tw.span`ml-1 text-purple-pastel font-normal text-xs`;
export const CommentText = tw.p`bg-green-pastel text-purple-pastel w-full rounded-2 bg-opacity-50 px-8 py-5 mt-3 sm:ml-5 sm:mt-0`;
export const CommentInput = tw.textarea`placeholder-purple-pastel border-1 border-green-pastel text-purple-pastel w-full rounded-2 px-6 py-3 h-auto sm:ml-5 mt-3 sm:mt-0 outline-none resize-none`;
export const CommentAdd = tw.button`absolute right-0 bottom-1 mr-5 text-purple-classic border-0 outline-none focus:outline-none `;
export const ButtonPurple = tw.button`outline-none lowercase focus:outline-none bg-purple-classic hover:bg-purple-pastel transition duration-300 text-white h-10 px-6`;
export const RoundedButton = tw.button`outline-none lowercase focus:outline-none bg-purple-classic hover:bg-purple-pastel transition duration-300 text-white h-10 px-6 rounded-full`;

export const FormWrapper = tw.div`w-9/12 mx-auto mt-5`;
export const Logo = tw.img`w-40 mx-auto pt-5`;
export const FormLine = tw.div`sm:flex sm:space-x-5`;
export const FormSepartor = tw.div`my-5 border-t-2 w-full`;

export const SubmitWrapper = tw.div`pb-8 flex flex-row-reverse`;
export const ErrorWrapper = tw.div`px-20 pb-20 mb-40 pt-10`;
export const Error = tw.div`text-red-500 text-center my-5 mt-10 h-20 text-2xl`;
export const DatePickerInput = tw.input`outline-none appearance-none`;

export const SignupStepWrapper = tw.div``;
export const SignupStepHeader = tw.div`flex`;
export const SignupStepTitleWrapper = tw.div`ml-3`;
export const SignupStepSubTitle = tw.h2`text-purple-pastel italic`;
export const SignupStepTitle = tw.h1`font-semibold text-2xl text-purple-pastel italic mt--2`;
export const SignupStepNumber = tw.img`h-12 w-12`;
export const SignupStepContent = tw.div`ml-0 mr-0 md:ml-1 md:mr-1 lg:ml-12 lg:mr-8 p-2 pt-5`;
export const SignupStepButtonWrapper = tw.div`ml-0 mr-0 md:ml--1 md:mr--1 lg:ml--12 lg:mr--8 flex items-center mb-6 mt-3`;
export const SignupStepButtonLine = tw.div`w-full border-b-2`;

export const SignupFormulaWrapper = tw.div`border-4 rounded-2xl flex flex-col justify-center items-center py-5 lg:py-12 text-center mb-5 cursor-pointer`;
export const SignupFormulaIcon = tw.img`h-16 ml-3`;
export const SignupFormulaPrice = tw.div`text-5xl font-medium mt-5`;
export const SignupFormulaCurrency = tw.div`font-light text-lg mt--3`;
export const SignupFormulaSub = tw.div`uppercase lg:text-lg font-medium mt-8 px-5 py-2 rounded-full mx-3`;

export const EditContainer = tw.div`absolute top-0 right-0 sm:right--0.5 sm:top-0 text-purple-classic inline-block`;
export const ButtonMore = tw.button`outline-none focus:outline-none`;
export const Dropdown = tw.div`rounded-md bg-white shadow-xs`;
export const DropdownItem = tw.a`block px-4 py-2 text-sm leading-5 text-purple-classic hover:bg-green-pastel focus:outline-none focus:bg-green-pastel`;
