import Constants from "../Constants";
import {store} from "../redux/store";
import {getLanguage} from "./languages";
import jwt_decode from "jwt-decode";

const axios = require('axios');

class UserService {

    isAuthenticated() {
        const jwt = store.getState().jwt;
        if (jwt === undefined) {
            return false;
        }
        const decoded = jwt_decode(jwt);
        if (new Date() >= decoded.exp * 1000) {
            //token expiration
            return false;
        }
        return true;
    }

    isAccountValid() {
        const state = store.getState()
        if (state.jwt === undefined || state.signupProcess) {
            return false;
        }
        const decoded = jwt_decode(state.jwt);
        if (new Date() >= decoded.exp * 1000) {
            //token expiration
            return false;
        }
        return true;
    }

    resetPassword(email) {
        return axios.post(
            Constants.baseApiUrl + Constants.resetPasswordEndpoint,
            {
                email: email,
                language: getLanguage().toUpperCase()
            });
    }

    updatePassword(password, token) {
        return axios.post(
            Constants.baseApiUrl + Constants.newPasswordEndpoint + token,
            {
                password: password,
            });
    }

    login(email, password) {
        return axios
            .post(Constants.baseApiUrl + Constants.loginEndpoint, {
                email,
                password
            });

    }

    getProfile() {
        return axios
            .get(Constants.baseApiUrl + Constants.profileEndpoint, this.getHeaders());
    }

    updateCusPicture(file) {
        const bodyFormData = new FormData();
        bodyFormData.append('file', file);

        return axios({
            method: 'post',
            url: Constants.baseApiUrl + Constants.updateCusImageEndpoint,
            data: bodyFormData,
            headers: {...this.getHeaders().headers, 'Content-Type': 'multipart/form-data'}
        });
    }

    updateCusProfile(payload) {
        return axios
            .post(
                Constants.baseApiUrl + Constants.updateCustomer,
                payload,
                this.getHeaders()
            );
    }

    updateSubPicture(file, subId, type) {
        const bodyFormData = new FormData();
        bodyFormData.append('file', file);

        return axios({
            method: 'post',
            url: Constants.baseApiUrl + Constants.updateSubImageEndpoint + subId + "/" + type,
            data: bodyFormData,
            headers: {...this.getHeaders().headers, 'Content-Type': 'multipart/form-data'}
        });
    }

    updateSubProfile(payload, subID) {
        return axios
            .post(
                Constants.baseApiUrl + Constants.updateSubscriber + subID,
                payload,
                this.getHeaders()
            );
    }

    updateSubPreferences(payload, subID) {
        return axios
            .post(
                Constants.baseApiUrl + Constants.updateSubscriberPref + subID,
                payload,
                this.getHeaders()
            );
    }

    getFamily(subId) {
        return axios
            .get(Constants.baseApiUrl + Constants.familyEndpoint + subId, this.getHeaders());
    }

    deleteFamilyMember(subId, cusId) {
        return axios
            .delete(Constants.baseApiUrl + Constants.deleteFamilyEndpoint + subId + "/" + cusId,
                this.getHeaders());
    }

    deleteFamilyAdmin(subId, cusId) {
        return axios
            .delete(Constants.baseApiUrl + Constants.deleteFamilyAdminEndpoint + subId + "/" + cusId,
                this.getHeaders());
    }

    addFamilyAdmin(subId, cusId) {
        return axios
            .post(Constants.baseApiUrl + Constants.addFamilyAdminEndpoint + subId + "/" + cusId,{},
                this.getHeaders());
    }

    sendEmailToFamily(subId, msg) {
        return axios
            .post(Constants.baseApiUrl + Constants.sendFamilyMessageEndpoint + subId,
                {
                    message: msg
                },
                this.getHeaders());
    }

    inviteInFamily(subId, email) {
        return axios
            .post(Constants.baseApiUrl + Constants.inviteFamilyEndpoint + subId,
                {
                    email: email
                },
                this.getHeaders());
    }

    getLastTransactions(subId) {
        return axios.get(
            Constants.baseApiUrl + Constants.lastTransactionsEndpoint + subId,
            this.getHeaders());
    }

    getHeaders() {
        const token = store.getState().jwt;
        return {
            headers: {Authorization: `Bearer ${token}`}
        };
    }

}

export default new UserService();
