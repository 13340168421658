import React from "react";
import {
    FormWrapper, Logo,
    Page,
    TwoGreenPoint
} from "./common/Elements";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import tw from "twin.macro";

import '../assets/react-input-phone.css';
import "react-datepicker/dist/react-datepicker.css";
import Carousel from 'react-elastic-carousel';
import SubscriptionService from "../services/subscription.service";

import logo from "../images/iris_logo_violet.png";
import signup1 from "../images/signup/magiris-inscription_img01.png";
import signup2 from "../images/signup/magiris-inscription_img02.png";
import signup3 from "../images/signup/magiris-inscription_img03.png";
import signup4 from "../images/signup/magiris-inscription_img04.png";
import signup5 from "../images/signup/magiris-inscription_img05.png";
import SignupStep1 from "./signup/SignupStep1";
import SignupStep2 from "./signup/SignupStep2";
import SignupStep3 from "./signup/SignupStep3";
import {toastError, toastSuccess} from "./common/Toast";
import SignupStep4 from "./signup/SignupStep4";
import UserService from "../services/user.service";
import {Redirect} from "react-router-dom";
import {setJwt, setProfile, setSignupProcess} from "../redux/actions";
import Tracking from "./common/Tracking";

const Container = tw.div`p-5 sm:pt-10v sm:pb-10v`;
const Content = tw.div`relative sm:w-9/12 sm:mx-auto rounded-lg bg-white pb-12`;
const RightGreenPointWrapper = tw.div`absolute right--10 hidden sm:block`;
const LeftGreenPointWrapper = tw.div`absolute left--10 mt-96 hidden sm:block`;
const Title = tw.h1`text-center font-semibold text-2xl text-purple-pastel pt-5 italic`;
const SubTitle = tw.h2`text-center text-purple-pastel pb-2 italic mt--2`;
const Text = tw.p`text-center font-light pt-5`;

const TwoCol = tw.div`md:flex md:flex-row-reverse mt-5`;
const Right = tw.div`w-full md:w-1/2 mb-8 md:mb-0`;
const Left = tw.div`w-full md:w-1/2`;

const CarouselItem = tw.div`my-2 h-68 md:h-full justify-center flex flex-col`;
const CarouselWrapperImg = tw.div`max-h-56 w-full md:max-h-full pb-2`;
const CarouselImg = tw.img`object-contain max-w-md w-full max-h-40 sm:max-h-56 md:max-h-full mx-auto`;
const CarouselText = tw.div`text-center font-semibold italic`;

class Signup extends React.Component {

    constructor(props) {
        super(props);

        let activeStep = 1;
        let userAuthenticated = false;
        let customer = {};
        if (UserService.isAuthenticated()) {
            activeStep = 2;
            userAuthenticated = true;
            customer = this.props.profile
        }

        this.state = {
            isMobile: true,
            activeStep: activeStep,
            userAuthenticated: userAuthenticated,
            customer: customer,
            subscriber: {},
            subName: "",
            subToken: "",
            redirect: ""
        };

        const subToken = this.props.match.params.subscriberToken;
        if (subToken !== undefined && subToken !== "new") {
            // invitation
            this.loadSubscriberInfo(subToken);
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    changeStep = (newStep) => {
        if (this.state.activeStep > newStep) {
            this.setState({
                activeStep: newStep
            });
        }
    }

    loadSubscriberInfo = (subToken) => {
        SubscriptionService.getSubscriberName(subToken)
            .then(response => {
                this.setState({
                    subName: response.data,
                    subToken: subToken,
                });
            })
            .catch(err => {
                toastError(this.props.t("ERROR_SERVER"))
            });
    }

    handleSubmit = (step, payload) => {
        const isInvitation = this.state.subName !== "";
        const {t} = this.props;
        if (step === 1) {
            this.setState({activeStep: 2, customer: payload})
        }
        if (step === 2) {
            if (isInvitation) {
                this.setState({activeStep: 4, subscriber: payload})
            } else {
                this.setState({activeStep: 3, subscriber: payload})
            }
        }
        if (step === 3) {
            if (payload === undefined) {
                //Reset formula
                this.setState({activeStep: 3, formula: undefined})
            } else {
                this.setState({activeStep: 4, formula: payload})
            }
        }
        if (step === 4) {
            if (isInvitation && !this.state.userAuthenticated) {
                const payloadRequest = this.state.customer;
                payloadRequest["subscriberToken"] = this.state.subToken;
                payloadRequest["newsletter"] = payload.newsletter;
                payloadRequest["relationship"] = this.state.subscriber.relationship;
                payloadRequest["relationShipOther"] = this.state.subscriber.relationShipOther;
                SubscriptionService.createCustomer(payloadRequest)
                    .then(response => {
                        const responseData = response.data;
                        let profile = {...responseData};
                        delete profile.jwt;

                        this.props.updateProfile(profile);
                        this.props.updateJwt(responseData.jwt);
                        this.setState({
                            redirect: "/home"
                        });
                    })
                    .catch(err => {
                        if (err.response.data === "BAD_EMAIL") {
                            toastError(t("SIGNUP_BAD_EMAIL_ERROR"));
                            this.setState({activeStep: 1})
                        } else {
                            toastError(t("ERROR_SERVER"));
                        }
                    });
            } else if (isInvitation && this.state.userAuthenticated) {
                // Association
                const payloadRequest = this.state.subscriber;
                payloadRequest["subscriberToken"] = this.state.subToken;
                payloadRequest["relationship"] = this.state.subscriber.relationship;
                payloadRequest["relationShipOther"] = this.state.subscriber.relationShipOther;
                SubscriptionService.associate(payloadRequest)
                    .then(response => {
                        toastSuccess(t("SIGNUP_JOIN_SUCCESS"));
                        setTimeout(() => {
                            this.setState({
                                redirect: "/home/" + this.state.subToken
                            });
                        }, 3000);
                    })
                    .catch(err => {
                        toastError(t("ERROR_SERVER"));
                    });
            } else if (!isInvitation && !this.state.userAuthenticated) {
                const payloadRequest = this.state.customer;
                payloadRequest["newsletter"] = payload.newsletter;
                SubscriptionService.createCustomer(payloadRequest)
                    .then(response => {
                        const payloadSubRequest = this.state.subscriber;
                        const responseData = response.data;
                        let profile = {...responseData};
                        delete profile.jwt;
                        // Create sub
                        SubscriptionService.createSubscriber(payloadSubRequest, responseData.jwt)
                            .then(response => {

                                this.props.updateProfile(profile);
                                this.props.updateJwt(responseData.jwt);

                                this.setState({
                                    redirect: {
                                        pathname: "/payment/" + response.data,
                                        state: {formula: this.state.formula}
                                    }
                                })
                            })
                            .catch(err => {
                                toastError(t("ERROR_SERVER"))
                            })
                    })
                    .catch(err => {
                        if (err.response.data === "BAD_EMAIL") {
                            toastError(t("SIGNUP_BAD_EMAIL_ERROR"));
                            this.setState({activeStep: 1})
                        } else {
                            toastError(t("ERROR_SERVER"));
                        }
                    });
            } else if (!isInvitation && this.state.userAuthenticated) {
                const payloadSubRequest = this.state.subscriber;
                SubscriptionService.createSubscriber(payloadSubRequest, this.props.jwt)
                    .then(response => {
                        this.setState({
                            redirect: {
                                pathname: "/payment/" + response.data,
                                state: {formula: this.state.formula}
                            }
                        })
                    })
                    .catch(err => {
                        toastError(t("ERROR_SERVER"))
                    })
            }
        }
    }

    resize() {
        this.setState({isMobile: window.innerWidth < 768});
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    render() {

        const {t} = this.props;

        if (this.state.redirect !== "") {
            return <Redirect to={this.state.redirect}/>
        }

        return (
            <Page>
                <Tracking/>
                <Container>
                    <Content>
                        <RightGreenPointWrapper>
                            <TwoGreenPoint/>
                        </RightGreenPointWrapper>
                        <LeftGreenPointWrapper>
                            <TwoGreenPoint/>
                        </LeftGreenPointWrapper>
                        <FormWrapper>
                            <Logo src={logo} alt={"Logo"}/>
                            <div className={"pb-5"}>
                                <Title>{t("SIGNUP_TITLE")}</Title>
                                <SubTitle>{t("SIGNUP_SUBTITLE")}</SubTitle>
                            </div>
                            <TwoCol>
                                <Right>
                                    <Carousel
                                        verticalMode itemsToShow={this.state.isMobile ? 1 : 5}
                                        isRTL={false}
                                        pagination={this.state.isMobile}
                                        showArrows={false}
                                        enableAutoPlay
                                        autoPlaySpeed={3500}
                                    >
                                        <CarouselItem>
                                            <CarouselWrapperImg>
                                                <CarouselImg src={signup1}/>
                                            </CarouselWrapperImg>
                                            <CarouselText dangerouslySetInnerHTML={{__html: t("SIGNUP_IMG1_TEXT")}}/>
                                        </CarouselItem>
                                        <CarouselItem>
                                            <CarouselImg src={signup2}/>
                                            <CarouselText dangerouslySetInnerHTML={{__html: t("SIGNUP_IMG2_TEXT")}}/>
                                        </CarouselItem>
                                        <CarouselItem>
                                            <CarouselImg src={signup3}/>
                                            <CarouselText dangerouslySetInnerHTML={{__html: t("SIGNUP_IMG3_TEXT")}}/>
                                        </CarouselItem>
                                        <CarouselItem>
                                            <CarouselImg src={signup4}/>
                                            <CarouselText dangerouslySetInnerHTML={{__html: t("SIGNUP_IMG4_TEXT")}}/>
                                        </CarouselItem>
                                        <CarouselItem>
                                            <CarouselImg src={signup5}/>
                                        </CarouselItem>
                                    </Carousel>
                                </Right>
                                <Left>
                                    <SignupStep1
                                        isActive={this.state.activeStep === 1}
                                        isInvitation={this.state.subName !== ""}
                                        subToken={this.state.subToken}
                                        handleSubmit={this.handleSubmit}
                                        changeStep={this.changeStep}
                                    />
                                    <SignupStep2
                                        isActive={this.state.activeStep === 2}
                                        handleSubmit={this.handleSubmit}
                                        isInvitation={this.state.subName !== ""}
                                        subName={this.state.subName}
                                        changeStep={this.changeStep}
                                    />
                                    <SignupStep3
                                        isActive={this.state.activeStep === 3}
                                        isInvitation={this.state.subName !== ""}
                                        subName={this.state.subName}
                                        handleSubmit={this.handleSubmit}
                                    />
                                    <SignupStep4
                                        isActive={this.state.activeStep === 4}
                                        isInvitation={this.state.subName !== ""}
                                        subName={this.state.subName}
                                        handleSubmit={this.handleSubmit}
                                    />
                                </Left>
                            </TwoCol>
                        </FormWrapper>
                    </Content>
                </Container>
            </Page>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        jwt: state.jwt,
        profile: state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateProfile: (profile) => dispatch(setProfile(profile)),
        updateJwt: (jwt) => dispatch(setJwt(jwt)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Signup));
