import Rotator from "./Rotator";

export function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function getClosureDates() {
    const today = new Date();
    let date1;
    let date2;

    if (today.getDate() >= 25) {
        date1 = new Date(today.getFullYear(), today.getMonth() + 1, 25);
        date2 = new Date(today.getFullYear(), today.getMonth() + 2, 25);
    } else {
        date1 = new Date(today.getFullYear(), today.getMonth(), 25);
        date2 = new Date(today.getFullYear(), today.getMonth() + 1, 25);
    }

    return [date1, date2];
}

export const autoRotateFile = (file) => new Promise((resolve, reject) => {
    if (!file.name.includes("jpg") && !file.name.includes("jpeg")) {
        reject("Not a jpg");
    } else {
        Rotator.createRotatedImage(file, 'blob',
            blob => {
                blob.lastModifiedDate = file.lastModifiedDate;
                blob.name = file.name;
                resolve(new File([blob], file.name));
            }
        )
    }
});
