import i18next from "i18next";

export function getLanguage() {
    let lang = i18next.language || window.localStorage.i18nextLng;
    return lang.substr(0, 2);
}

export function getLocale() {
    return i18next.language;
}
