import {getLocale} from "./languages";

export function priceFormat(currency, amount) {
    return new Intl.NumberFormat(getLocale(), {
        style: 'currency',
        currency: currency
    }).format(amount)
}

export function priceFormulaFormat(currency, amount) {
    if (currency === "CHF") {
        return amount + ".-";
    }
    return amount;
}
