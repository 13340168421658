import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import tw from "twin.macro";
import {ReactComponent as DownIcon} from "feather-icons/dist/icons/chevron-down.svg";
import {ReactComponent as UpIcon} from "feather-icons/dist/icons/chevron-up.svg";

const MagazineSelect = tw.div`
text-white bg-purple-classic lg:text-purple-classic outline-none
relative mt-5 py-3 w-full rounded-full pl-5 lg:bg-white
lg:absolute lg:py-0 lg:rounded-none lg:w-auto lg:text-lg
lg:block lg:top-13 lg:right-3 lg:h-10 lg:mt--5 lg:px-3
xl:top-16 xl:right-5`;

const MagazineOption = tw.div`w-full ml-2 mr-10 mt-1`;
const MagazineName = tw.div`font-brick hidden sm:inline-block`;
const MagazineNamePrefix = tw.div`hidden sm:inline-block mr-1`;

const DropdownButton = tw.div`w-full outline-none focus:outline-none`;
const Dropdown = tw.div`rounded-md bg-white shadow-xs`;
const DropdownItem = tw.a`block px-4 py-2 text-sm leading-5 text-purple-classic hover:bg-green-pastel focus:outline-none focus:bg-green-pastel`;

class MagazineSelector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            magazines: this.props.magazines,
            currentMagazine: this.props.currentMagazine,
            sub: this.props.sub,
            isOpen: false
        };

        this.dateOption = {
            day: 'numeric',
            month: 'long',
        };

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef !== undefined && this.wrapperRef.current !== null && !this.wrapperRef.current.contains(event.target)) {
            this.setState({isOpen: false});
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentMagazine !== this.props.currentMagazine) {
            this.setState({
                magazines: this.props.magazines,
                currentMagazine: this.props.currentMagazine,
                sub: this.props.sub
            })
        }
    }

    toggleMenu = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    magazineSelected = (id) => {
        this.props.magazineSelected(id);
        this.toggleMenu();
    }

    render() {

        const {t} = this.props;

        if (this.state.currentMagazine === undefined) {
            return null
        }

        const dateStr = new Date(this.state.currentMagazine.endDate).toLocaleDateString(this.props.language, this.dateOption);

        return (
            <MagazineSelect
                ref={this.wrapperRef}
                value={this.state.currentMagazine.magazineId}
                onChange={this.magazineSelected}
            >
                <DropdownButton
                    onClick={() => {
                        this.toggleMenu();
                    }}
                >
                    <MagazineOption
                        key={this.state.currentMagazine.magazineId}
                    >
                        {t("HOME_MAGAZINE_SELECT_PREFIX")}{" "}
                        <MagazineNamePrefix>
                            {t("HOME_MAGAZINE_SELECT_NAME_PREFIX") + " "}
                        </MagazineNamePrefix>
                        <MagazineName>
                            {this.state.sub.firstName}
                        </MagazineName>
                        {" "}{t("HOME_MAGAZINE_SELECT_DATE_PREFIX")}{" "}{dateStr}
                    </MagazineOption>
                </DropdownButton>
                {
                    this.state.isOpen ? (
                        <UpIcon
                            onClick={() => this.toggleMenu()}
                            className="absolute top-1 lg:top-0.5 right-1"
                        />
                    ) : (
                        <DownIcon
                            onClick={() => this.toggleMenu()}
                            className="absolute top-1 lg:top-0.5 right-1"
                        />
                    )
                }
                {
                    this.state.isOpen ? (
                        <Dropdown>
                            <div
                                className="origin-top-right absolute right-0 mt-2 w-50 rounded-md shadow-lg z-30">
                                <div className="rounded-md bg-white shadow-xs">
                                    <div className="py-1">
                                        {
                                            this.state.magazines.map((magazine) => {
                                                const dateStr = new Date(magazine.endDate).toLocaleDateString(this.props.language, this.dateOption);

                                                return (
                                                    <DropdownItem
                                                        key={magazine.magazineId}
                                                        onClick={() => {
                                                            this.magazineSelected(magazine.magazineId)
                                                        }}
                                                    >
                                                        {t("HOME_MAGAZINE_SELECT_PREFIX")}{" "}
                                                        <MagazineNamePrefix>
                                                            {t("HOME_MAGAZINE_SELECT_NAME_PREFIX") + " "}
                                                        </MagazineNamePrefix>
                                                        <MagazineName>
                                                            {this.state.sub.firstName}
                                                        </MagazineName>
                                                        {" "}{t("HOME_MAGAZINE_SELECT_DATE_PREFIX")}{" "}{dateStr}
                                                    </DropdownItem>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </Dropdown>
                    ) : null
                }

            </MagazineSelect>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        language: state.profile.language
    }
};

export default connect(mapStateToProps, null)(withTranslation()(MagazineSelector));
