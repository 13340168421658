import React, {Suspense} from 'react';
import tw from "twin.macro";
import i18n from "./i18n.js";
import {I18nextProvider} from "react-i18next";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react'
import Home from "./pages/Home";
import {store, persistor} from "./redux/store";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Payment from "./pages/payment/Payment";
import {ToastContainer} from "react-toastify";
import ResetPasswordForm from "./pages/ResetPasswordForm";
import ResetPassword from "./pages/ResetPassword";
import * as Sentry from "@sentry/react";

window.store = store;

const Body = tw.div`font-display`;

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <I18nextProvider i18n={i18n}>
                    <Suspense fallback="loading">
                        <Sentry.ErrorBoundary fallback={"An error has occurred"}>
                            <Body>
                                <ToastContainer/>
                                <Router>
                                    <Switch>
                                        <Route path="/signup/:subscriberToken/:customerId" component={Signup}/>
                                        <Route path="/signup/:subscriberToken" component={Signup}/>
                                        <Route path="/signup" component={Signup}/>
                                        <Route path="/payment/:token/:showClosureSelect" component={Payment}/>
                                        <Route path="/payment/:token" component={Payment}/>
                                        <Route path="/login" component={Login}/>
                                        <Route path="/resetpassword/:token" component={ResetPassword}/>
                                        <Route path="/resetpassword" component={ResetPasswordForm}/>
                                        <Route path="/" component={Home}/>
                                    </Switch>
                                </Router>
                            </Body>
                        </Sentry.ErrorBoundary>
                    </Suspense>
                </I18nextProvider>
            </PersistGate>
        </Provider>
    );
}

export default App;
