import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import tw from "twin.macro";
import CommentForm from "./CommentForm";
import CommentList from "./CommentList";
import MagazineService from "../../../services/magazine.service";
import {ReactComponent as More} from "feather-icons/dist/icons/more-vertical.svg";
import {ButtonMore, ButtonPurple, Dropdown, DropdownItem, EditContainer} from "../../common/Elements";
import {toastError, toastSuccess} from "../../common/Toast";
import {Img} from "react-image";
import ContentLoader from "react-content-loader";
import Viewer from 'react-viewer';

const PublicationContainer = tw.div`border-l-1 border-green-pastel pl-8 pb-16 sm:pl-10 relative`;
const PublicationProfileImage = tw.img`block rounded-full w-16 h-16 ml--16 sm:w-20 sm:h-20 sm:ml--20`;
const PublicationContent = tw.div`pl-3 mt--16 sm:pl-5 sm:mt--20 pt-2 sm:pt-3`;
const PublicationAuthor = tw.h1`text-purple-classic text-xl font-medium mb--1`;
const PublicationDate = tw.h2`text-purple-pastel text-sm`;
const PublicationText = tw.p`text-purple-pastel 
focus:outline-none focus:border-2 focus:border-green-classic focus:rounded`;
const UpdateButton = tw.div`mt-2 mb-5 justify-between flex-row flex`;

const PublicationInput = tw.textarea`text-purple-pastel w-full outline-none h-40 resize-none 
border-1 border-purple-classic rounded-lg p-3`;

class Publication extends React.Component {

    constructor(props) {
        super(props);

        this.dateOption = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit'
        };

        this.dateOptionUpdt = {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit'
        };

        this.state = {
            publication: this.props.publication,
            nowCommented: false,
            editMenuOpen: false,
            editing: false,
            newPublicationTxt: this.props.publication.content,
            deleted: false,
            viewerOpen: false
        };

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && this.wrapperRef.current !== null && !this.wrapperRef.current.contains(event.target)) {
            this.setState({editMenuOpen: false});
        }
    }

    loadAllComments = () => {
        const publicationId = this.state.publication.publicationId;
        MagazineService.loadFullPublication(publicationId)
            .then(response => {
                this.setState({
                    publication: response.data
                });
            })
            .catch(err => {
                toastError(this.props.t("ERROR_SERVER"));
            });
    }

    haveNewComment = () => {
        this.loadAllComments();
        this.setState({
            nowCommented: true
        })
    }


    refreshComments = () => {
        this.loadAllComments();
    }

    clickMore = () => {
        this.setState({
            editMenuOpen: !this.state.editMenuOpen
        })
    }

    deletePublication = () => {
        MagazineService.deletePublication(this.state.publication.publicationId)
            .then(response => {
                toastSuccess(this.props.t("PUBLICATION_DELETE_SUCCESS"))
                this.setState({
                    deleted: true
                });
                this.props.onDelete();
            })
            .catch(err => {
                this.clickMore();
                toastError(this.props.t("ERROR_SERVER"))
            });
    }

    updatePublication = () => {
        this.clickMore();
        this.setState({
            editing: true
        });
    }

    saveChanges = () => {
        MagazineService.updatePublication(this.state.publication.publicationId, this.state.newPublicationTxt)
            .then(resp => {
                const publication = {...this.state.publication};
                publication.content = this.state.newPublicationTxt;
                this.setState({
                    editing: false,
                    publication: publication
                });
            })
            .catch(err => {
                toastError(this.props.t("ERROR_SERVER"))
            });
    }

    cancelChanges = () => {
        this.setState({
            editing: false,
            newPublicationTxt: this.props.publication.content
        });
    }

    contentUpdate = (e) => {
        this.setState({
            newPublicationTxt: e.target.value
        })
    }

    render() {
        if (this.state.deleted) {
            return null
        }

        const {t} = this.props;
        const mag = this.props.magazine;

        const dateStr = new Date(this.state.publication.publicationDate).toLocaleDateString(this.props.language, this.dateOption);
        let updateDate = undefined;
        if (this.state.publication.updateDate !== null) {
            updateDate = new Date(this.state.publication.updateDate).toLocaleDateString(this.props.language, this.dateOptionUpdt);
        }

        const owner = this.props.profile.customerId === this.state.publication.authorId;
        const admin = this.props.subscriber.admin;

        return (
            <PublicationContainer>
                <Viewer
                    visible={this.state.viewerOpen}
                    onClose={() => {
                        this.setState({viewerOpen: false})
                    }}
                    images={[{src: this.state.publication.picture, alt: ''}]}
                    noToolbar={true}
                    noFooter={true}
                    zoomable={true}
                    onMaskClick={() => {
                        this.setState({viewerOpen: false})
                    }}
                />
                {
                    ((owner || admin) && mag.open) && (
                        <EditContainer ref={this.wrapperRef}>
                            <ButtonMore onClick={this.clickMore}>
                                <More/>
                            </ButtonMore>
                            {
                                this.state.editMenuOpen ? (
                                    <Dropdown>
                                        <div
                                            className="origin-top-right absolute right-0 mt-2 w-50 rounded-md shadow-lg">
                                            <div className="rounded-md bg-white shadow-xs">
                                                <div className="py-1" role="menu">
                                                    {owner &&
                                                    <DropdownItem
                                                        onClick={this.updatePublication}>{t("UPDATE_BUTTON")}</DropdownItem>}
                                                    {(owner || admin) &&
                                                    <DropdownItem
                                                        onClick={this.deletePublication}>{t("DELETE_BUTTON")}</DropdownItem>}
                                                </div>
                                            </div>
                                        </div>
                                    </Dropdown>
                                ) : null
                            }

                        </EditContainer>
                    )
                }
                <PublicationProfileImage
                    src={this.state.publication.authorPicture}
                    alt={"Profile"}/>
                <PublicationContent>
                    <PublicationAuthor>{this.state.publication.author}</PublicationAuthor>
                    <PublicationDate>
                        {t("PUBLICATION_DATE_PREFIX")} {" "}
                        {dateStr}
                        {
                            updateDate ? (
                                <span> ({t("PUBLICATION_DATE_UPDATE_PREFIX")} {updateDate})</span>
                            ) : null
                        }
                    </PublicationDate>
                    <a onClick={() => this.setState({viewerOpen: true})}>
                        <Img
                            className={"my-3 max-h-48"}
                            src={this.state.publication.picture}
                            loader={
                                <ContentLoader
                                    speed={2}
                                    width={400}
                                    height={200}
                                    style={{width: '100%'}}
                                    backgroundColor={"rgba(216,243,234,0.25)"}
                                    foregroundColor={"#d8f3ea"}
                                >
                                    <rect x="0" y="0" rx="1" ry="15" width="100%" height="100%"/>
                                </ContentLoader>
                            }
                        />
                    </a>
                    {
                        this.state.editing ? (
                            <div>
                                <PublicationInput
                                    onChange={this.contentUpdate}
                                    value={this.state.newPublicationTxt}
                                    maxLength={"120"}
                                />
                                <UpdateButton>
                                    <ButtonPurple
                                        onClick={this.cancelChanges}>{t("PUBLICATION_UPDATE_CANCEL")}</ButtonPurple>
                                    <ButtonPurple
                                        onClick={this.saveChanges}>{t("PUBLICATION_UPDATE_SUBMIT")}</ButtonPurple>
                                </UpdateButton>
                            </div>
                        ) : (
                            <PublicationText>
                                {this.state.publication.content}
                            </PublicationText>
                        )
                    }
                    <CommentList
                        key={this.state.publication.comments}
                        publication={this.state.publication}
                        comments={this.state.publication.comments}
                        nbComments={this.state.publication.nbComments}
                        loadAll={this.loadAllComments}
                        showEnd={this.state.nowCommented}
                        refreshCallback={this.refreshComments}
                        magazineOpen={mag.open}
                    />
                    {
                        mag.open ? (
                            <CommentForm publication={this.state.publication} newCommentCallback={this.haveNewComment}/>
                        ) : null
                    }
                </PublicationContent>
            </PublicationContainer>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        subscriber: state.currentSub !== undefined ? state.profile.subscribers[state.currentSub] : undefined,
        language: state.profile.language,
        profile: state.profile
    }
};

export default connect(mapStateToProps, null)(withTranslation()(Publication));
