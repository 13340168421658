import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import React from "react";
import PublicationForm from "./publication/PublicationForm";
import Loader from "../common/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import Publication from "./publication/Publication";
import tw from "twin.macro";
import MagazineService from "../../services/magazine.service";

const AddPublicationContainer = tw.div`bg-white rounded-lg`;

const LoaderWrapper = tw.div`text-center`;
const PublicationsBloc = tw.div`bg-white rounded-lg pb-6 pt-10 pl-10 pr-10 sm:pl-16 sm:pr-16 mb-8 mt-5 h-auto`;
const ProgressBarWrapper = tw.div`flex mt-5 items-center`;
const ProgressBar = tw.div`h-015r bg-green-classic bg-opacity-25 w-full rounded-full`;
const ProgressBarTxt = tw.div`flex-1 ml-3 text-purple-pastel text-sm`;
const ProgressBarContent = tw.div`bg-purple-pastel rounded-full bg-opacity-100`;

const AlertBloc = tw.div`bg-white rounded-lg p-6 pl-10 sm:pl-16 h-auto text-purple-pastel`;

class Feed extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            page: 0,
            publications: [],
            hasMore: true,
            error: false
        };
    }

    componentDidMount() {
        this.loadPublications();
    }

    reloadMagazines = () => {
        this.props.reloadMagazine();
    }

    reloadPublications = () => {
        this.reloadMagazines();
        this.setState({
            page: 0,
            publications: [],
            loading: true,
            hasMore: true
        }, this.loadPublications)
    }

    loadPublications = () => {
        const magazineID = this.props.currentMagazine.magazineId;
        let page = this.state.page;
        let publications = this.state.publications;
        MagazineService.loadPublications(magazineID, page)
            .then(response => {
                page++;
                let hasMore = response.data.length !== 0;
                this.setState({
                    publications: [...publications, ...response.data],
                    page: page,
                    loading: false,
                    hasMore: hasMore,
                    error: false
                })
            })
            .catch(err => {
                this.setState({
                    error: true
                })
            });
    }

    getRemaningPubText = () => {
        const remaning = this.props.currentMagazine.totalPublications - this.props.currentMagazine.postedPublications;
        if (remaning > 1) {
            return remaning + ' ' + this.props.t('HOME_PUBLICATIONS_REMANING');
        } else {
            return remaning + ' ' + this.props.t('HOME_PUBLICATION_REMANING');
        }
    };

    getProgessBarStyle = () => {
        if (this.props.currentMagazine !== undefined) {

            let percentage = this.props.currentMagazine.postedPublications / this.props.currentMagazine.totalPublications;
            percentage = percentage * 100;
            return {
                width: percentage + "%",
                height: "100%"
            }
        }
    }

    render() {

        const {t} = this.props;
        const sub = this.props.subscriber;

        return (
            <div>
                <AddPublicationContainer>
                    {
                        this.props.currentMagazine === undefined ? (
                            <AlertBloc>{t("ERROR_SERVER")}</AlertBloc>
                        ) : this.props.currentMagazine.open && this.props.currentMagazine.full ? (
                            <AlertBloc>{t("HOME_MAGAZINE_FULL")}</AlertBloc>
                        ) : this.props.currentMagazine.open ? (
                            <PublicationForm sub={sub} newPublicationCallback={this.reloadPublications}/>
                        ) : (
                            <AlertBloc>{t("HOME_MAGAZINE_CLOSED")}</AlertBloc>
                        )
                    }
                </AddPublicationContainer>
                {
                    this.state.loading ? (
                        <LoaderWrapper><Loader/></LoaderWrapper>
                    ) : this.state.error ? (
                        <AlertBloc>{t("ERROR_SERVER")}</AlertBloc>
                    ) : (
                        <div>
                            {
                                this.props.currentMagazine !== undefined && this.props.currentMagazine.open &&
                                <ProgressBarWrapper>
                                    <ProgressBar>
                                        <ProgressBarContent style={this.getProgessBarStyle()}/>
                                    </ProgressBar>
                                    <ProgressBarTxt style={{whiteSpace: 'nowrap'}}>
                                        {this.getRemaningPubText()}
                                    </ProgressBarTxt>
                                </ProgressBarWrapper>
                            }
                            <PublicationsBloc>
                                <InfiniteScroll
                                    style={{overflow: "visible"}}
                                    next={this.loadPublications}
                                    hasMore={this.state.hasMore}
                                    loader={<LoaderWrapper><Loader/></LoaderWrapper>}
                                    dataLength={this.state.publications.length}
                                >
                                    {
                                        this.state.publications.length === 0 ? (
                                            <div className={"mb-3 text-purple-pastel"}>{t("HOME_MAGAZINE_EMPTY")}</div>
                                        ) : null
                                    }
                                    {
                                        this.state.publications.map((publication) => {
                                            return (
                                                <Publication
                                                    magazine={this.props.currentMagazine}
                                                    onDelete={this.reloadMagazines}
                                                    key={publication.publicationId}
                                                    publication={publication}
                                                />
                                            )
                                        })
                                    }
                                </InfiniteScroll>
                            </PublicationsBloc>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default connect(null, null)(withTranslation()(Feed));
