import React from "react";
import {withTranslation} from "react-i18next";
import Header from "./common/Header";
import {connect} from "react-redux";
import {ButtonPurple, ContainerWithDecorator, Page, TwoGreenPoint} from "./common/Elements";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import {Link, Redirect, Route, Switch} from "react-router-dom";
import MagazineService from "../services/magazine.service";
import SubSelector from "./common/SubSelector";
import MagazineSelector from "./common/MagazineSelector";
import Feed from "./main/Feed";
import Family from "./main/Family";
import Subscription from "./main/Subscription";
import History from "./main/History";
import UserService from "../services/user.service";
import {disconnect, setCurrentSub, setJwt, setProfile} from "../redux/actions";
import Loader from "./common/Loader";
import Profile from "./Profile";
import {toastError} from "./common/Toast";
import SubProfile from "./SubProfile";
import {ReactComponent as DownIcon} from "feather-icons/dist/icons/chevron-down.svg";
import SmartBanner from "react-smartbanner";
import 'react-smartbanner/dist/main.css';
import Tracking from "./common/Tracking";

const BannerImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 lg:h-64 xl:h-80 w-full bg-cover bg-center rounded-lg`}
`;

const LoaderWrapper = tw.div`text-center`;
const NoSubContainer = tw.div`p-5 sm:pt-10v sm:pb-10v`;
const NoSubContent = tw.div`relative sm:w-9/12 sm:mx-auto rounded-lg bg-white p-10`;

const ProfileImage = tw.img`block rounded-full w-40 h-40 mx-auto border-3 border-white mt--28`;
const TwoColumn = tw.div`lg:flex mt-5`;
const Left = tw.div`flex-col lg:w-1/4 lg:mr-3`;
const LeftContent = tw.div`bg-white rounded-lg mt--12 lg:mb-5 pt-12 pb-10`;
const SubscriberName = tw.h1`text-center font-brick text-2xl text-purple-classic pt-2 text-1.7 hover:underline`;
const SubscriberSeeProfile = tw.a`text-center block mx-auto lowercase text-purple-pastel hover:underline mt--1`;
const LeftButton = styled.span`
  ${tw`hidden lg:block w-full text-center font-medium rounded-full h-10 leading-10 mb-3 transition duration-300 hover:text-white hover:bg-purple-classic lowercase`}
  ${props => props.active ? tw`text-white bg-purple-classic` : tw`bg-white text-purple-classic`}
`;

const LeftGreenContainer = tw.div`mt-16 hidden lg:block`;

const Right = tw.div`flex-col lg:w-3/4 lg:ml-3 mt-5 lg:mt-0`;

const reloadInterval = 60000;

class Home extends React.Component {

    constructor(props) {
        super(props);

        let enableTracking = false;

        if (this.props.location.pathname.includes("/home/")) {
            let token = this.props.location.pathname.replace("/home/", "");
            this.reloadProfile(token);
            enableTracking = true;
        } else {
            this.reloadProfile("");
        }

        this.state = {
            loading: true,
            magazines: [],
            currentMagazine: undefined,
            error: false,
            popupMagazine: false,
            isAccountValid: UserService.isAccountValid(),
            enableTracking: enableTracking,
            redirect: undefined
        };

        this.paths = [
            "home",
            "family",
            "subscription",
            "history"
        ];

        this.reloadInterval = null;
        if (this.props.subscriber === undefined) {
            this.reloadInterval = setInterval(() => this.reloadProfile(""), 4000);
        } else {
            this.reloadInterval = setInterval(() => this.reloadProfile(""), reloadInterval);
        }
    }

    componentDidMount() {
        if (this.state.isAccountValid) {
            this.loadMagazines();
        }

        if (window.ReactNativeWebView !== undefined) {
            window.ReactNativeWebView.postMessage("JWT_ON_HOME_" + this.props.jwt);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.subscriber !== this.props.subscriber) {
            clearInterval(this.reloadInterval);
            this.reloadInterval = setInterval(() => this.reloadProfile(""), reloadInterval);
            this.newSubSelected();
        }
    }

    componentWillUnmount() {
        clearInterval(this.reloadInterval);
    }

    reloadProfile = (token) => {
        UserService.getProfile()
            .then(resp => {
                const profile = resp.data;
                const jwt = profile.jwt;
                delete profile.jwt;
                this.props.updateProfile(profile);
                const toSelect = profile.subscribers.filter(sub => sub.inviteToken === token);
                if (toSelect.length > 0) {
                    this.props.updateCurrentSub(toSelect[0].subscriberId);
                }
                if (jwt !== undefined && jwt != null && jwt !== "") {
                    this.props.updateJwt(jwt);
                }
            })
            .catch(err => {
                const isValid = UserService.isAccountValid();
                this.setState({
                    isAccountValid: isValid
                });
            });
    }

    newSubSelected = () => {
        this.setState({
            loading: true,
            currentMagazine: undefined
        }, this.loadMagazines)
    }

    loadMagazines = () => {
        if (this.props.subscriber === undefined) {
            return;
        }

        MagazineService.loadMagazines(this.props.subscriber.subscriberId)
            .then(response => {
                const magazines = response.data;

                if (magazines === undefined || magazines.length === undefined || magazines.length === 0) {
                    this.setState({
                        loading: false,
                        magazines: [],
                        currentMagazine: undefined
                    });
                } else {
                    this.setState({
                        magazines: magazines,
                        currentMagazine: magazines[0]
                    });
                }
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: true
                });
            });
    }

    openMagazinePopup = () => {
        this.setState({
            popupMagazine: true
        });
    }

    closeMagazinePopup = () => {
        this.setState({
            popupMagazine: false
        });
    }

    magazineSelected = (id) => {
        const current = this.state.magazines.filter(mag => mag.magazineId == id);
        this.setState({
            currentMagazine: current[0]
        });
    }

    redirectToSignup = () => {
        this.setState({
            redirect: "/signup/new"
        })
    }

    render() {

        const {t, match} = this.props;

        const sub = this.props.subscriber;

        if (!this.state.isAccountValid || !UserService.isAccountValid()) {
            return <Redirect to={"/login"}/>
        }
        if (this.state.redirect !== undefined) {
            return <Redirect to={this.state.redirect}/>
        }

        if (sub === undefined) {
            const registerDate = new Date(this.props.profile.registerDate);
            const diffHour = Math.abs(new Date() - registerDate) / 36e5;
            return this.renderNoSub(diffHour < 1);
        }

        return (

            <Page>
                {this.state.enableTracking && <Tracking/>}
                <SmartBanner title={'Mag-iris'}/>
                {this.state.popupMagazine && <SubSelector closeFunction={this.closeMagazinePopup}/>}
                <Header
                    subscriber={sub}
                    menus={this.paths}
                />
                <ContainerWithDecorator>
                    <Switch>
                        <Route path={`${match.path}profile`}>
                            <Profile reloadCallback={() => this.reloadProfile("")}/>
                        </Route>
                        <Route path={`${match.path}sub`}>
                            <SubProfile reloadCallback={() => this.reloadProfile("")}/>
                        </Route>
                        <Route path={`${match.path}`}>
                            <BannerImage imageSrc={sub.coverImage}/>
                            <TwoColumn>
                                <Left>
                                    <ProfileImage src={sub.image}/>
                                    <LeftContent>
                                        <Link to={"/sub"}>
                                            <SubscriberName>{sub.firstName}</SubscriberName>
                                        </Link>
                                        <SubscriberSeeProfile
                                            onClick={this.openMagazinePopup}
                                        >
                                            {t("HOME_CHANGE_PROFILE")}
                                            <DownIcon
                                                style={{height: 20, width: 20}}
                                                className={"text-purple-pastel inline-block"}
                                            />
                                        </SubscriberSeeProfile>
                                    </LeftContent>
                                    {
                                        this.paths.map(path => {
                                            return (
                                                <Link
                                                    key={path}
                                                    to={"/" + path}
                                                >
                                                    <LeftButton
                                                        active={this.props.location.pathname.includes(path)}
                                                    >
                                                        {t("HOME_MENU_" + path.toUpperCase())}
                                                    </LeftButton>
                                                </Link>
                                            )
                                        })
                                    }
                                    <LeftGreenContainer>
                                        <TwoGreenPoint/>
                                    </LeftGreenContainer>
                                </Left>
                                <MagazineSelector
                                    magazines={this.state.magazines}
                                    currentMagazine={this.state.currentMagazine}
                                    sub={sub}
                                    magazineSelected={this.magazineSelected}
                                />
                                <Right>
                                    <Switch>
                                        <Route path={`${match.path}home`}>
                                            {this.state.currentMagazine !== undefined && <Feed
                                                key={this.state.currentMagazine.magazineId}
                                                currentMagazine={this.state.currentMagazine}
                                                subscriber={sub}
                                                reloadMagazine={this.loadMagazines}
                                            />}
                                        </Route>
                                        <Route path={`${match.path}family`}>
                                            <Family
                                                key={sub.subscriberId}
                                                subscriber={sub}
                                            />
                                        </Route>
                                        <Route path={`${match.path}subscription`}>
                                            <Subscription/>
                                        </Route>
                                        <Route path={`${match.path}history`}>
                                            <History
                                                key={sub.subscriberId}
                                            />
                                        </Route>
                                        <Redirect to={`${match.path}home`}/>
                                    </Switch>
                                </Right>
                            </TwoColumn>
                        </Route>
                    </Switch>
                </ContainerWithDecorator>
            </Page>
        )
    }

    renderNoSub(afterSignup) {

        const {t} = this.props;

        return (
            <Page>
                <NoSubContainer>
                    <NoSubContent>
                        {
                            afterSignup ? (
                                <>
                                    {t("HOME_WAITING_PROFILE")}
                                    <LoaderWrapper><Loader/></LoaderWrapper>
                                </>
                            ) : (
                                <>
                                    <div>
                                        {t("HOME_NOSUB_ERROR")}
                                    </div>
                                    <ButtonPurple
                                        onClick={this.redirectToSignup}
                                        className={"mt-3"}
                                    >
                                        {t("HOME_POPUP_ADD")}
                                    </ButtonPurple>
                                    <ButtonPurple
                                        onClick={this.props.disconnect}
                                        className={"mt-3 ml-5"}
                                    >
                                        {t("HEADER_LOGOUT")}
                                    </ButtonPurple>
                                </>
                            )
                        }
                    </NoSubContent>
                </NoSubContainer>
            </Page>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        jwt: state.jwt,
        profile: state.profile,
        language: state.profile.language,
        subscriber: state.currentSub !== undefined ? state.profile.subscribers[state.currentSub] : undefined,
        magazines: state.magazines,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateProfile: (profile) => dispatch(setProfile(profile)),
        updateCurrentSub: (subId) => dispatch(setCurrentSub(subId)),
        updateJwt: (jwt) => dispatch(setJwt(jwt)),
        disconnect: () => dispatch(disconnect())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Home));
