import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "./assets/styles.css";
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from "@sentry/react";
import Constants from "./Constants";
import {Integrations} from "@sentry/tracing";

Sentry.init({
    dsn: Constants.sentryDNS,
    environment: Constants.env,
    integrations: [
        new Integrations.BrowserTracing(),
    ],
});

ReactDOM.render(
    <>
        <App/>
    </>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
