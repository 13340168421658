import Constants from "../Constants";
import {store} from "../redux/store";

const axios = require('axios');

class MagazineService {

    getHeaders() {
        const token = store.getState().jwt;
        return {
            headers: {Authorization: `Bearer ${token}`}
        };
    }

    loadMagazines(subscriberId) {
        return axios
            .get(
                Constants.baseApiUrl + Constants.magazinesEndpoint + subscriberId,
                this.getHeaders()
            )
    }

    loadOldMagazines(subscriberId) {
        return axios
            .get(
                Constants.baseApiUrl + Constants.oldMagazinesEndpoint + subscriberId,
                this.getHeaders()
            )
    }

    loadPublications(magazineId, pageIdx) {
        return axios
            .get(
                Constants.baseApiUrl + Constants.publicationsEndpoint + magazineId + "/" + pageIdx,
                this.getHeaders()
            )
    }

    uploadPublicationImage(subscriberId, file) {
        const bodyFormData = new FormData();
        bodyFormData.append('file', file);

        return axios({
            method: 'put',
            url: Constants.baseApiUrl + Constants.publicationUploadImageEndpoint + subscriberId,
            data: bodyFormData,
            headers: {...this.getHeaders().headers, 'Content-Type': 'multipart/form-data'}
        });
    }

    addPublication(subscriberId, content, picture) {
        const data = {
            content: content,
            picture: picture
        };

        return axios
            .put(
                Constants.baseApiUrl + Constants.publicationEndpoint + subscriberId,
                data,
                this.getHeaders(),
            )
    }

    // Publication with all comments
    loadFullPublication(publicationId) {
        return axios
            .get(
                Constants.baseApiUrl + Constants.publicationEndpoint + publicationId,
                this.getHeaders()
            )
    }

    deletePublication(publicationId) {
        return axios.delete(
            Constants.baseApiUrl + Constants.publicationEndpoint + publicationId,
            this.getHeaders()
        )
    }

    updatePublication(publicationId, text) {
        return axios.post(
            Constants.baseApiUrl + Constants.publicationEndpoint + publicationId,
            {
                newContent: text
            }, this.getHeaders());
    }

    commentPublication(publicationId, comment) {
        const data = {
            content: comment,
        };

        return axios
            .put(
                Constants.baseApiUrl + Constants.commentPublicationEndpoint + publicationId,
                data,
                this.getHeaders(),
            )
    }

    deleteComment(commentID) {
        return axios.delete(
            Constants.baseApiUrl + Constants.commentPublicationEndpoint + commentID,
            this.getHeaders()
        )
    }
}

export default new MagazineService();
