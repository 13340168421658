import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Button, FormLine, FormWrapper, Input, Logo, Page, SubmitWrapper, Title} from "./common/Elements";
import tw from "twin.macro";
import logo from "../images/iris_logo_violet.png";
import {Formik} from "formik";
import * as yup from "yup";
import {Redirect} from "react-router-dom";
import UserService from "../services/user.service";
import {toastError, toastSuccess} from "./common/Toast";

const Container = tw.div`p-5 h-screen sm:pt-10v`;
const Content = tw.div`sm:h-80v sm:w-9/12 sm:mx-auto rounded-lg bg-white pt-3 pb-8 sm:py-10 text-center`;

class ResetPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            submitAlreadyClick: false,
            token: this.props.match.params.token,
            redirect: undefined
        }

        const {t} = this.props;
        this.schema = yup.object({
            password: yup.string().required(" ") // /!\ Do not remove space
                .min(8, t("SIGNUP_PASSWORD_SIZE_ERROR"))
                .max(30, t("SIGNUP_PASSWORD_SIZE_ERROR"))
                .matches(/[a-z]/, t("SIGNUP_PASSWORD_LOWER_ERROR"))
                .matches(/[A-Z]/, t("SIGNUP_PASSWORD_UPPER_ERROR"))
                .matches(/[0-9]/, t("SIGNUP_PASSWORD_NUMBER_ERROR")),
        });
    }

    clickSubmit = () => {
        this.setState({
            submitAlreadyClick: true
        });
    }

    handleSubmit = (values) => {
        const {t} = this.props;
        UserService.updatePassword(values.password, this.state.token)
            .then(resp => {
                toastSuccess(t("RESET_PASSWORD_FORM_SUCCESS"));
                this.setState({
                    redirect: "/login"
                });
            })
            .catch(err => {
                toastError(t("ERROR_SERVER"));
            });
    };

    render() {
        if (this.state.redirect !== undefined) {
            return <Redirect to={this.state.redirect}/>
        }

        const {t} = this.props;

        return (
            <Page>
                <Container>
                    <Content>
                        <Logo src={logo} alt={"Logo"}/>
                        <Title>{t("RESET_PASSWORD_FORM_TITLE")}</Title>
                        <Formik
                            validationSchema={this.schema}
                            onSubmit={this.handleSubmit}
                            initialValues={{}}
                        >
                            {({
                                  handleSubmit,
                                  handleChange,
                                  handleBlur,
                                  values,
                                  touched,
                                  isValid,
                                  errors,
                                  isSubmitting,
                                  setValues
                              }) => (
                                <form onSubmit={handleSubmit} className={"w-64 mx-auto mt-2"}>
                                    <FormLine className="text-red-500 ml-1 mb-1">
                                        {
                                            errors.password ? errors.password : errors.passwordConfirm
                                        }
                                    </FormLine>
                                    <FormLine>
                                        <Input
                                            placeholder={t("RESET_PASSWORD_FORM_PLACHOLDER")}
                                            name={"password"}
                                            type={"password"}
                                            value={values.password || ''}
                                            onChange={handleChange}
                                            isInvalid={this.state.submitAlreadyClick && errors.password}
                                        />
                                    </FormLine>
                                    <SubmitWrapper>
                                        <Button
                                            onClick={() => this.clickSubmit()}
                                            type={"submit"}
                                        >
                                            {t("RESET_PASSWORD_FORM_SUBMIT")}
                                        </Button>
                                    </SubmitWrapper>
                                </form>
                            )}
                        </Formik>
                    </Content>
                </Container>
            </Page>
        )
    }
}

export default connect(null, null)(withTranslation()(ResetPassword));
