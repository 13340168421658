import React, {createRef} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'
import ImageUploader from "react-images-upload";
import {RoundedButton, Title} from "../common/Elements";
import UserService from "../../services/user.service";
import Loader from "../common/Loader";
import {toastError, toastSuccess} from "../common/Toast";
import {Redirect} from "react-router-dom";

class UpdateSubPicture extends React.Component {

    constructor(props) {
        super(props);

        if (this.props.type === "PROFILE") {
            this.state = {
                src: null,
                crop: {
                    unit: "%",
                    height: 90,
                    aspect: 1
                },
                uploading: false,
                redirect: "",
                circularCrop: true
            }
        } else if (this.props.type === "COVER") {
            this.state = {
                src: null,
                crop: {
                    unit: "%",
                    height: 90,
                    aspect: 16 / 9
                },
                uploading: false,
                redirect: "",
                circularCrop: false
            }
        }

        this.onDrop = this.onDrop.bind(this);
        this.imageRef = createRef();
    }

    onDrop = (pictureFile, pictureDataURLs) => {
        this.setState({
            src: pictureDataURLs[0]
        });
    }

    setCrop = (crop) => {
        this.setState({
            crop: crop
        });
    }

    imageLoaded = (image) => {
        this.imageRef = image;
    }

    submitNewPicture = async () => {
        const {t} = this.props;
        this.setState({uploading: true});

        const fileImage = await this.getCroppedImg();

        UserService
            .updateSubPicture(fileImage, this.props.subscriber.subscriberId, this.props.type)
            .then(resp => {
                toastSuccess(t("PROFILE_UPDATE_PICTURE_SUCCESS"));
                this.props.reloadCallback();
                this.setState({
                    uploading: false,
                    redirect: "/home"
                });
            })
            .catch(err => {
                this.setState({uploading: false});
                toastError(t("ERROR_SERVER"))
            });
    }

    getCroppedImg = () => {
        const crop = this.state.crop;
        const image = this.imageRef;

        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                blob.name = "profile.jpg";
                resolve(blob);
            }, 'image/jpeg', 1);
        });
    }

    render() {

        if (this.state.uploading) {
            return <div className={"text-center"}><Loader/></div>
        }

        if (this.state.redirect !== "") {
            return <Redirect to={this.state.redirect}/>
        }

        const {t} = this.props;

        return (
            <div>
                <Title>{t("SUB_UPDATE_PICTURE_TITLE")}</Title>
                {
                    this.state.src === null ? (
                        <ImageUploader
                            withIcon={true}
                            buttonText={t("HOME_PICTURE_CHOOSE")}
                            label={t("HOME_PICTURE_LABEL")}
                            fileSizeError={t("HOME_PICTURE_SIZE_ERROR")}
                            fileTypeError={t("HOME_PICTURE_TYPE_ERROR")}
                            singleImage={true}
                            onChange={this.onDrop}
                            imgExtension={[".jpg", ".png", ".jpeg"]}
                            maxFileSize={5242880}
                            buttonStyles={{
                                backgroundColor: "#4d4dff"
                            }}
                        />
                    ) : (
                        <div className={"flex flex-col"}>
                            <ReactCrop
                                src={this.state.src}
                                onImageLoaded={this.imageLoaded}
                                crop={this.state.crop}
                                onChange={this.setCrop}
                                keepSelection={true}
                                circularCrop={this.state.circularCrop}
                            />
                            <RoundedButton
                                className={"px-5 mx-auto mt-5"}
                                onClick={this.submitNewPicture}
                            >
                                {t("PROFILE_UPDATE_PICTURE_SUBMIT")}
                            </RoundedButton>
                        </div>
                    )
                }
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        subscriber: state.currentSub !== undefined ? state.profile.subscribers[state.currentSub] : undefined,
    }
};

export default connect(mapStateToProps, null)(withTranslation()(UpdateSubPicture));
