import tw from "twin.macro";
import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import UserService from "../../services/user.service";
import Loader from "../common/Loader";
import {toastError, toastSuccess} from "../common/Toast";
import {ReactComponent as SendIcon} from "feather-icons/dist/icons/send.svg";
import {ButtonMore, ButtonPurple, Dropdown, DropdownItem, EditContainer, Input, TitleBis} from "../common/Elements";
import plus from "../../images/plus.png";
import {validateEmail} from "../../services/tools";
import {ReactComponent as More} from "feather-icons/dist/icons/more-vertical.svg";

const LoaderWrapper = tw.div`text-center`;
const Container = tw.div`bg-white rounded-lg px-5 pb-5 relative`;

const Member = tw.div`flex items-center justify-between mb-3`;
const MemberPicture = tw.img`rounded-full h-16 w-16 mr-3`;
const MemberName = tw.div`text-lg w-full text-purple-classic font-medium`;
const MemberPublications = tw.div`hidden md:block text-center text-purple-classic`;
const MemberRole = tw.span`text-sm text-purple-classic font-light`;
const MoreWrapper = tw.div`ml-10 mb-5 relative flex items-center justify-center `;
const InviteButton = tw.button`outline-none focus:outline-none text-purple-classic`

const MessageTextInput = tw.textarea`w-full placeholder-purple-classic text-purple-classic 
italic outline-none resize-none h-32 border-b-1 border-green-pastel mt-2`;
const MessageWrapper = tw.div`text-right px-3 pt-5 pb-6`;
const Error = tw.div`text-center font-medium text-red-500 pb-3`;
const SendingWrapper = tw.div`absolute rounded-lg bg-white 
bg-opacity-75 text-center w-full ml--5 h-56 pt-16`;

class Family extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            subscriber: this.props.subscriber,
            members: [],
            emailText: "",
            error: "",
            sending: false,
            inviteEmail: "",
            inviteEmailValid: true,
            invitationSending: false,
            moreOpenId: null
        };

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        this.loadFamily();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (!event.target.classList.contains("feather-more-vertical") && !event.target.classList.contains("dropdown-item")) {
            this.setState({moreOpenId: null});
        }
    }


    loadFamily = () => {
        UserService.getFamily(this.props.subscriber.subscriberId)
            .then(res => {
                this.setState({
                    loading: false,
                    members: res.data,
                    moreOpenId: null
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                });
                toastError(this.props.t("ERROR_SERVER"));
            })
    }

    emailTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            error: ""
        });
    }

    sendEmail = () => {
        const {t} = this.props;

        if (this.state.emailText.length < 20) {
            this.setState({
                error: t("FAMILY_MESSAGE_TEXT_ERROR")
            });
            return;
        }

        this.setState({
            sending: true
        });

        UserService.sendEmailToFamily(this.props.subscriber.subscriberId, this.state.emailText)
            .then(resp => {
                this.setState({
                    sending: false,
                    emailText: "",
                    error: ""
                });
                toastSuccess(t("FAMILY_MESSAGE_SUCCESS"));
            })
            .catch(err => {
                this.setState({
                    sending: false,
                    error: t("FAMILY_MESSAGE_ERROR")
                });
            });
    }

    inviteEmailChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            inviteEmailValid: true
        });
    }

    sendInvitation = () => {
        if (this.state.invitationSending) {
            return;
        }

        if (!validateEmail(this.state.inviteEmail)) {
            this.setState({
                inviteEmailValid: false
            });
            return;
        }

        this.setState({invitationSending: true});

        const {t} = this.props;

        UserService.inviteInFamily(this.props.subscriber.subscriberId, this.state.inviteEmail)
            .then(resp => {
                toastSuccess(t("FAMILY_INVITE_SUCCESS"));
                this.setState({
                    inviteEmail: "",
                    inviteEmailValid: true,
                    invitationSending: false
                });
            })
            .catch(err => {
                this.setState({
                    invitationSending: false
                });
                toastError(t("ERROR_SERVER"));
            });
    }

    promote = (id, name) => {
        const {t} = this.props;
        const text = t("FAMILY_PROMOTE_CONFIRMATION").replace("%NAME%", name);
        if (window.confirm(text)) {
            UserService.addFamilyAdmin(this.props.subscriber.subscriberId, id)
                .then(resp => {
                    toastSuccess(t("FAMILY_PROMOTE_SUCCESS"));
                    this.loadFamily();
                })
                .catch(err => {
                    toastError(t("ERROR_SERVER"));
                });
        }
    }

    revoke = (id, name) => {
        const {t} = this.props;
        const text = t("FAMILY_REVOKE_CONFIRMATION").replace("%NAME%", name);
        if (window.confirm(text)) {
            UserService.deleteFamilyAdmin(this.props.subscriber.subscriberId, id)
                .then(resp => {
                    toastSuccess(t("FAMILY_REVOKE_SUCCESS"));
                    setTimeout(() => window.location.reload(), 3000)
                })
                .catch(err => {
                    toastError(t("FAMILY_REVOKE_ERROR"));
                });
        }
    }

    delete = (id, name) => {
        const {t} = this.props;
        const text = t("FAMILY_DELETE_CONFIRMATION").replace("%NAME%", name);
        if (window.confirm(text)) {
            UserService.deleteFamilyMember(this.props.subscriber.subscriberId, id)
                .then(resp => {
                    toastSuccess(t("FAMILY_DELETE_SUCCESS"));
                    this.loadFamily();
                })
                .catch(err => {
                    toastError(t("FAMILY_DELETE_ERROR"));
                });
        }
    }

    quit = (id) => {
        const {t} = this.props;
        const text = t("FAMILY_QUIT_CONFIRMATION");
        if (window.confirm(text)) {
            UserService.deleteFamilyMember(this.props.subscriber.subscriberId, id)
                .then(resp => {
                    toastSuccess(t("FAMILY_QUIT_CONFIRM"));
                    setTimeout(() => window.location.reload(), 3000)
                })
                .catch(err => {
                    toastError(t("FAMILY_DELETE_ERROR"));
                });
        }
    }


    render() {
        if (this.state.loading) {
            return <LoaderWrapper><Loader/></LoaderWrapper>
        }

        const {t} = this.props;

        return (
            <Container>
                <TitleBis>{t("FAMILY_INVITE_TITLE")}</TitleBis>
                <Member>
                    <MemberPicture src={plus} alt={"Profile"}/>
                    <Input
                        className={"mt-2"}
                        name={"inviteEmail"}
                        type={"email"}
                        placeholder={t("FAMILY_INVITE_PLACEHOLDER")}
                        onChange={this.inviteEmailChange}
                        value={this.state.inviteEmail}
                        isInvalid={!this.state.inviteEmailValid}
                    />
                    <InviteButton
                        onClick={this.sendInvitation}
                    >
                        <SendIcon className={"ml-5 mb-1 mr-2"}/>
                    </InviteButton>
                </Member>
                <TitleBis>{t("FAMILY_MESSAGE_TITLE")}</TitleBis>
                {this.state.sending && <SendingWrapper><Loader/></SendingWrapper>}
                <MessageWrapper>
                    {this.state.error !== "" && <Error>{this.state.error}</Error>}
                    <MessageTextInput
                        placeholder={t("FAMILY_MESSAGE_PLACEHOLDER")}
                        name={"emailText"}
                        onChange={this.emailTextChange}
                        value={this.state.emailText}
                    />
                    <ButtonPurple
                        className={"mt-2"}
                        onClick={this.sendEmail}
                    >
                        {t("FAMILY_MESSAGE_SEND")}
                    </ButtonPurple>
                </MessageWrapper>
                <TitleBis>{t("FAMILY_TITLE")}</TitleBis>
                {
                    this.state.members.map(member => {

                        const loginAdmin = this.props.subscriber.admin;
                        const loginMember = member.customerId === this.props.profile.customerId;
                        const memberAdmin = member.admin;

                        return (
                            <Member
                                key={member.customerId}
                            >
                                <MemberPicture src={member.picture} alt={"Profile"}/>
                                <MemberName>
                                    {member.firstName + " " + member.lastName}
                                    <MemberRole>
                                        {member.admin ? " - " + t("FAMILY_ADMIN_LABEL") : ""}
                                    </MemberRole>
                                </MemberName>
                                <MemberPublications>
                                    {member.nbPublication + " "}
                                    {member.nbPublication > 1 ? t("FAMILY_PUBLICATIONS_LABEL") : t("FAMILY_PUBLICATION_LABEL")}
                                </MemberPublications>
                                {(loginMember || loginAdmin) && <MoreWrapper>
                                    <EditContainer>
                                        <ButtonMore onClick={() => {
                                            if (member.customerId === this.state.moreOpenId) {
                                                this.setState({moreOpenId: null});
                                            } else {
                                                this.setState({moreOpenId: member.customerId});
                                            }
                                        }}>
                                            <More/>
                                        </ButtonMore>
                                        {
                                            this.state.moreOpenId !== null && this.state.moreOpenId === member.customerId ? (
                                                <Dropdown key={member.customerId}>
                                                    <div
                                                        className="origin-top-right absolute right-0 mt-2 w-50 rounded-md shadow-lg z-50">
                                                        <div className="rounded-md bg-white shadow-xs z-50">
                                                            <div className="py-1" role="menu">
                                                                {
                                                                    !loginAdmin && loginMember &&
                                                                    <DropdownItem
                                                                        className={"dropdown-item"}
                                                                        onClick={() => {
                                                                            this.quit(member.customerId)
                                                                        }}
                                                                    >
                                                                        {t("FAMILY_QUIT_LABEL")}
                                                                    </DropdownItem>
                                                                }
                                                                {
                                                                    loginAdmin && !loginMember &&
                                                                    <DropdownItem
                                                                        className={"dropdown-item"}
                                                                        onClick={() => {
                                                                            this.delete(member.customerId, member.firstName);
                                                                        }}
                                                                    >
                                                                        {t("FAMILY_EXCLUDE_LABEL")}
                                                                    </DropdownItem>
                                                                }
                                                                {
                                                                    loginAdmin && !memberAdmin &&
                                                                    <DropdownItem
                                                                        className={"dropdown-item"}
                                                                        onClick={() => {
                                                                            this.promote(member.customerId, member.firstName);
                                                                        }}
                                                                    >
                                                                        {t("FAMILY_PROMOTE_LABEL")}
                                                                    </DropdownItem>
                                                                }
                                                                {
                                                                    loginAdmin && memberAdmin &&
                                                                    <DropdownItem
                                                                        className={"dropdown-item"}
                                                                        onClick={() => {
                                                                            this.revoke(member.customerId, member.firstName);
                                                                        }}
                                                                    >
                                                                        {t("FAMILY_REVOKE_LABEL")}
                                                                    </DropdownItem>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Dropdown>
                                            ) : null
                                        }

                                    </EditContainer>
                                </MoreWrapper>
                                }
                            </Member>
                        )
                    })
                }
            </Container>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        subscriber: state.currentSub !== undefined ? state.profile.subscribers[state.currentSub] : undefined,
    }
};

export default connect(mapStateToProps, null)(withTranslation()(Family));
